export enum UserType {
    ADMIN = 'admin',
    ACCOUNT_MANAGER = 'account-manager',
    CUSTOMER = 'customer',
    OPERATOR = 'operator',
    CONTROL_PROVIDER = 'control-provider',
    UTILITY_CUSTOMER = 'utility-customer',
}

export interface IUser {
    user_id: number;
    company_id: number;
    company?: any | null;
    user_fname: string;
    user_lname: string;
    user_email: string;
    user_phone: string;
    user_type: UserType;
    user_password: string | undefined;
    notification_settings: {
        event_created: {
            email: boolean;
            sms: boolean;
        };
        pre_event: {
            email: boolean;
            sms: boolean;
        };
        event_started: {
            email: boolean;
            sms: boolean;
        };
        event_completed: {
            email: boolean;
            sms: boolean;
        };
    };
    mfa_enabled: boolean;
    user_feature_flag: string[];
    user_super_admin: boolean;
    site_label: string[];
    tokens?: { accessToken: string };
    user_date_last_login: string;
    user_date_created: string;
    user_date_modified: string;
}

export interface IMfaSetting {
    uuid: string;
    require: string[];
    message?: string;
    infoMessage?: string;
}

export interface IConfirmationCode {
    code: number;
}

export class MfaRequiredError extends Error {
    uuid: string;
    require: any[];
    message: string;
    infoMessage: string;

    constructor(error: any) {
        super(error);
        this.name = 'mfaRequiredError';
        this.uuid = error.uuid;
        this.require = error.require;
        this.message = error.message || '';
        this.infoMessage = error.infoMessage || '';
    }
}

export class MfaSetupError extends Error {
    user: IUser;
    message: string;
    require: string[];

    constructor(error: any) {
        super(error);
        this.name = 'mfaSetUpError';
        this.user = error.user;
        this.require = error.require;
        this.message = error.message || '';
    }
}

export interface IChangePassword {
    userId: number;
    oldPassword: string;
    newPassword: string;
}
