import { ExclamationCircleOutlined, HistoryOutlined } from '@ant-design/icons';
import DeleteOutlined from '@ant-design/icons/DeleteOutlined';
import DownloadOutlined from '@ant-design/icons/DownloadOutlined';
import LoadingOutlined from '@ant-design/icons/LoadingOutlined';
import LockOutlined from '@ant-design/icons/LockOutlined';
import PlusCircleOutlined from '@ant-design/icons/PlusCircleOutlined';
import Tooltip from 'antd/es/tooltip';
import App from 'antd/lib/app';
import Button from 'antd/lib/button';
import Table, { TablePaginationConfig } from 'antd/lib/table';
import { ColumnsType, FilterValue, SorterResult } from 'antd/lib/table/interface';
import Typography from 'antd/lib/typography';
import isEmpty from 'lodash/isEmpty';
import { useContext, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';

import { AbilityContext } from '../../components/ability/can';
import { LoadingIcon } from '../../components/icons/LoadingIcon';
import { AuditTrailModal } from '../../components/modals/AuditTrailModal/AuditTrailModal';
import { PageHeader } from '../../components/pageHeader/pageHeader';
import { ProgramFormModal } from '../../components/program/ProgramFormModal';
import { SyncSalesforceButton } from '../../components/salesforce/SyncSalesforceButton/SyncSalesforceButton';
import { SearchInput } from '../../components/searchInput/searchInput';
import { ActionList } from '../../components/table/actionList/actionList';
import { buildUtilityCustomerFilter, getColumnFilteredValue, getColumnSortOrder } from '../../components/table/columnFormatHelpers';
import { WithEmptyDataTable } from '../../components/table/withEmptyDataTable';
import { useDocumentTitle } from '../../components/useDocumentTitle';
import { JobName } from '../../domain/async-job/interface';
import { AuditEntityType } from '../../domain/audit-trail/interface';
import { useAuth } from '../../domain/auth/useAuth';
import { DEFAULT_PAGINATION, PAGE_ACTION } from '../../domain/commonConst';
import { IPaginationMeta } from '../../domain/IPagination';
import { exportPrograms } from '../../domain/program';
import { IProgram, ProgramUtilityAutomation } from '../../domain/program/interface';
import { ProgramUtilityAutomationOptions } from '../../domain/program/programUtilityAutomationOptions';
import {
    useProgramDeleteMutation,
    useProgramListQuery,
    useProgramOperatorsListQuery,
    useProgramTypesListQuery,
} from '../../domain/program/queries';
import { exportSites } from '../../domain/site';
import { toUsefulParams } from '../toUsefulParams';
import { usePageLocation } from '../usePageState';
import { getTablePaginationParams } from 'src/components/table/getTablePaginationParams';
import { compareWithLocale } from 'src/domain/compareWithLocale';
import { UtilityOrIsoTag } from 'src/components/UtilityOrIsoTag';
import { useCompanies } from 'src/components/selectors/GlobalCompanySelector/CompanyContext';
import { filterCompaniesByType } from 'src/components/selectors/GlobalCompanySelector/companyHelpers';
import { COMPANY_TYPE } from 'src/domain/company/interface';

export const defaultState = {
    pagination: DEFAULT_PAGINATION,
    sorter: {
        field: 'enrolled_sites_count',
        order: 'descend',
    },
    filter: {},
};

export const Programs = () => {
    const { notification, modal } = App.useApp();
    const location = useLocation();
    const { companies } = useCompanies()!;
    const { setPageQuery, queryToState } = usePageLocation();
    const pageState: any = queryToState(location.search);

    useDocumentTitle('Programs');

    const [program, setProgram] = useState<IProgram | Partial<IProgram> | null>(null);
    const [exportLoading, setExportLoading] = useState<boolean>(false);
    const [sitesExportLoading, setSitesExportLoading] = useState<boolean>(false);
    const [pageAction, setPageAction] = useState<PAGE_ACTION | ''>();

    const auth = useAuth()!;
    const ability = useContext(AbilityContext);

    const utilityCustomerCompanies = useMemo(
        () => filterCompaniesByType(companies, COMPANY_TYPE.UTILITY_CUSTOMER),
        [companies]
    );

    const { mutate: deleteProgram, isLoading: isProgramDeleteLoading } = useProgramDeleteMutation();
    // const { mutate: restoreProgram, isLoading: isProgramRestoreLoading } = useProgramRestoreMutation();
    // const { mutate: archiveProgram, isLoading: isProgramArchiveLoading } = useProgramArchiveMutation();

    const query = isEmpty(pageState) ? defaultState : pageState;
    const params = toUsefulParams(query);

    // Set default sorter if it's not in the state to highlight the column
    if (!pageState.sorter) {
        pageState.sorter = defaultState.sorter;
    }

    if (!params?.sorter?.field || !params?.sorter?.order) {
        params.sorter = defaultState.sorter;
    }

    const listQuery = {
        pagination: params?.pagination ?? DEFAULT_PAGINATION,
        ...(params?.filter && { filter: params?.filter }),
        ...(params?.sorter && { sorter: params?.sorter }),
        ...(params?.search && { search: params?.search }),
        include: 'enrolled-sites-count,products,utility_customer',
        company_id: params?.company_id ?? null,
    };
    const {
        data,
        isLoading,
        isError,
        error,
        refetch: refetchProgramList,
    } = useProgramListQuery(listQuery, {
        keepPreviousData: true,
    });

    const { data: operatorsData } = useProgramOperatorsListQuery();
    const operators = operatorsData || [];

    const { data: typesData } = useProgramTypesListQuery();
    const types = typesData || [];

    const programs = data?.data || [];
    const meta = data?.meta || ({} as IPaginationMeta);

    if (isError) {
        notification.error({ key: 'programs-fetch-error', message: error?.message || 'Cannot fetch programs!' });
    }

    function showNewProgramModal() {
        setPageAction(PAGE_ACTION.NEW_EDIT);
        setProgram({
            program_id: undefined,
            name: undefined,
            iso: undefined,
            utility_id: undefined,
            type_id: undefined,
            access_partner_id: undefined,
            description: undefined,
            utility_customer_id: undefined
        });
    }

    const showPageActionModal = (record: IProgram, action: PAGE_ACTION) => () => {
        setPageAction(action);
        setProgram(record);
    };

    function deleteProgramWithConfirm(record: IProgram) {
        if (isProgramDeleteLoading) return;

        modal.confirm({
            icon: <ExclamationCircleOutlined />,
            content: (
                <div>
                    <Typography.Text>
                        Deleting program <strong>{record.name || ''}</strong>.
                    </Typography.Text>
                    <br />
                    <Typography.Text>
                        This action will automatically unenroll all sites and unlink all products from this program. Are
                        you certain you want to proceed?
                    </Typography.Text>
                </div>
            ),
            onOk() {
                deleteProgram(record);
            },
            onCancel() {
                console.log('Cancel');
            },
        });
    }

    async function exportProgramsAction() {
        try {
            setExportLoading(true);
            await exportPrograms(pageState);
        } catch (err: any) {
            notification.error({ key: 'programs-export-error', message: err.message || 'Cannot export programs!' });
        }
        setExportLoading(false);
    }

    async function onTableChange(
        pagination: TablePaginationConfig,
        filter: Record<string, FilterValue | null>,
        sorter: SorterResult<IProgram> | SorterResult<IProgram>[]
    ) {
        const preparedParams = toUsefulParams({ pagination, sorter, filter });
        setPageQuery({ ...pageState, ...preparedParams });
    }

    async function onProgramFormModalClose() {
        setPageAction('');
        setProgram(null);
    }

    function downloadAttachedSites(record: IProgram) {
        return async () => {
            setSitesExportLoading(true);
            try {
                await exportSites({ filter: { program_id: record.program_id } });
            } catch (err: any) {
                notification.error({
                    key: 'sites-export-error',
                    message: err.message || 'Cannot export sites by program!',
                });
            }
            setSitesExportLoading(false);
        };
    }

    const onSearch = async (value: string) => {
        setPageQuery({ ...pageState, search: value });

        // if search value is the same as in the state, then we need to refetch data and set pagination to 1 page
        if (value === (pageState.search || '')) {
            listQuery.pagination.current = DEFAULT_PAGINATION.current;

            await refetchProgramList(listQuery);
        }
    };

    const actions = (programRecord: IProgram) => {
        const actionList = [
            ...(ability.can('update', 'Program')
                ? [
                    {
                        title: 'Edit',
                        onClick: (record: IProgram) => showPageActionModal(record, PAGE_ACTION.NEW_EDIT),
                    },
                ]
                : []),
            {
                title: (record: IProgram) =>
                    record.enrolled_sites_count === 0
                        ? 'No sites enrolled to program to download'
                        : 'Download sites attached to program',
                onClick: (record: IProgram) =>
                    sitesExportLoading || record.enrolled_sites_count === 0 ? null : downloadAttachedSites(record),
                icon: (record: IProgram) =>
                    sitesExportLoading ? (
                        <LoadingOutlined spin style={{ fontSize: 18 }} />
                    ) : (
                        <DownloadOutlined style={{ color: record.enrolled_sites_count === 0 ? '#ccc' : 'inherit' }} />
                    ),
            },
            ...(auth.user?.isAdminRoleType()
                ? [
                    {
                        title: 'Audit Trail',
                        onClick: (record: IProgram) => showPageActionModal(record, PAGE_ACTION.AUDIT_TRAIL),
                        icon: <HistoryOutlined />,
                    },
                ]
                : []),

            ...(auth.user?.isAdmin()
                ? [
                    {
                        title: 'Delete Program',
                        onClick: (record: IProgram) => () =>
                            record.program_locked ? null : deleteProgramWithConfirm(record),
                        icon: (record: IProgram) => (
                            <LoadingIcon
                                icon={
                                    <DeleteOutlined style={{ color: record.program_locked ? '#ccc' : 'inherit' }} />
                                }
                                loading={isProgramDeleteLoading}
                            />
                        ),
                    },
                ]
                : []),
        ];

        return actionList;
    };

    const allowedActor = useMemo(() => !auth.user?.isCustomer(), [auth.user]);

    const byPermissionColumns = useMemo(() => {
        return [
            ...(allowedActor
                ? [
                    {
                        title: 'Salesforce Products Linked',
                        dataIndex: 'products_count',
                        sorter: (a: IProgram, b: IProgram) =>
                            Number(a.products?.length) - Number(b.products?.length)!,
                        ...getColumnSortOrder('products_count', pageState.sorter),
                        render: (_products: any, record: { products: string | any[] }) =>
                            record.products?.length || 0,
                    },
                ]
                : []),
            ...(allowedActor
                ? [
                    {
                        title: 'Utility Automation',
                        dataIndex: 'utility_automation',
                        defaultSortOrder: 'descend',
                        filterMultiple: false,
                        filters: ProgramUtilityAutomationOptions.map(option => ({ ...option, text: option.label })),
                        ...getColumnFilteredValue('utility_automation', pageState.filter),
                        render: (text: any, record: { utility_automation: ProgramUtilityAutomation }) =>
                            ProgramUtilityAutomationOptions.find(option => option.value === record.utility_automation)
                                ?.label,
                    },
                ]
                : []),
        ];
    }, [allowedActor, pageState.filter, pageState.sorter]) as ColumnsType<IProgram>;

    const columns: ColumnsType<IProgram> = [
        {
            title: 'Name',
            dataIndex: 'name',
            sorter: (a, b) => compareWithLocale(a.name, b.name),
            ...getColumnSortOrder('name', pageState.sorter),
            render: (programName, record) => (
                <div style={{ maxWidth: '180px' }}>
                    {record.program_locked && (
                        <Tooltip
                            title={
                                <div style={{ maxWidth: '110px' }}>
                                    This program is locked as it`s associated with DRMS automation. Please contact
                                    admin.
                                </div>
                            }
                        >
                            <LockOutlined style={{ marginRight: 4 }} />
                        </Tooltip>
                    )}
                    {programName}
                </div>
            ),
        },
        {
            title: 'Utility Customer',
            dataIndex: 'utility_customer_id',
            sorter: (a, b) => compareWithLocale(a.utility_customer?.company_name, b.utility_customer?.company_name),
            ...getColumnSortOrder('utility_customer_id', pageState.sorter),
            filters: buildUtilityCustomerFilter(utilityCustomerCompanies),
            ...getColumnFilteredValue('utility_customer_id', pageState.filter),
            filterSearch: (input, record) => !!record?.text?.toString()?.toLowerCase().includes(input.toLowerCase()),
            render: (text, record) => record?.utility_customer?.company_name,
        },
        {
            title: 'Utility/ISO',
            dataIndex: 'utility_id',
            render: (text, record) => {
                return <UtilityOrIsoTag utilityOrIso={record.iso || record.utility_name} />
            },
        },
        {
            title: 'Operator',
            dataIndex: 'access_partner_id',
            sorter: (a, b) => compareWithLocale(a.access_partner_id, b.access_partner_id),
            ...getColumnSortOrder('access_partner_id', pageState.sorter),
            filters: operators,
            ...getColumnFilteredValue('access_partner_id', pageState.filter),
            filterSearch: (input, record) => !!record?.text?.toString()?.toLowerCase().includes(input.toLowerCase()),
            render: (text, record) => record?.access_partner?.name,
        },
        {
            title: 'Type',
            dataIndex: 'type_id',
            sorter: (a, b) => compareWithLocale(a.type_id, b.type_id),
            ...getColumnSortOrder('type_id', pageState.sorter),
            filters: types,
            ...getColumnFilteredValue('type_id', pageState.filter),
            filterSearch: (input, record) => !!record?.text?.toString()?.toLowerCase().includes(input.toLowerCase()),
            render: (text, record) => record?.type?.name,
        },
        {
            title: 'Sites Enrolled',
            dataIndex: 'enrolled_sites_count',
            sorter: (a: IProgram, b: IProgram) => a.enrolled_sites_count! - b.enrolled_sites_count!,
            ...getColumnSortOrder('enrolled_sites_count', pageState.sorter),
            render: (count, record) => count || 0,
        },
        ...byPermissionColumns,
        {
            key: 'action',
            sorter: false,
            render: (text: string, record) => <ActionList actions={actions(record)} item={record} />,
            width: 160,
        },
    ];

    return (
        <>
            <PageHeader
                pageTitle={`Programs (${meta.total || 0})`}
                actions={[
                    <SearchInput onSearch={onSearch} defaultValue={pageState?.search} key="search-program" />,
                    <Button
                        size="large"
                        key="download-csv"
                        data-cy="download-data"
                        onClick={exportProgramsAction}
                        icon={<DownloadOutlined />}
                        loading={exportLoading}
                    >
                        Download CSV
                    </Button>,
                    ability.can('create', 'AsyncJob') && (
                        <SyncSalesforceButton jobName={JobName.SYNC_SALESFORCE_PRODUCTS} key='salesforce-sync' />
                    ),
                    ability.can('create', 'Program') && (
                        <Button
                            size="large"
                            key="new-program"
                            type="primary"
                            onClick={showNewProgramModal}
                            icon={<PlusCircleOutlined />}
                        >
                            New Program
                        </Button>
                    ),
                ]}
            />
            <WithEmptyDataTable condition={isLoading}>
                <Table
                    size="small"
                    rowKey="program_id"
                    sticky
                    columns={columns}
                    dataSource={programs}
                    pagination={getTablePaginationParams(data?.meta)}
                    loading={isLoading}
                    onChange={onTableChange}
                />
            </WithEmptyDataTable>

            {program && pageAction === PAGE_ACTION.NEW_EDIT && (
                <ProgramFormModal program={program} onClose={onProgramFormModalClose} />
            )}

            {program && pageAction === PAGE_ACTION.AUDIT_TRAIL && (
                <AuditTrailModal
                    entityId={program.program_id!}
                    entityType={AuditEntityType.PROGRAM}
                    onClose={onProgramFormModalClose}
                />
            )}
        </>
    );
};
