import { ThemeConfig } from 'antd';

export const PRIMARY_COLOR = '#2fadab';
export const SECONDARY_COLOR = '#888787';
export const SUCCESS_COLOR = '#26ad5f';
export const WARNING_COLOR = '#fa962a';
export const BASE_COLOR = '#444444';
export const INFO_COLOR = '#52affa';
export const ERROR_COLOR = '#fa675c';
export const DISABLED_COLOR = '#cccccc';

const defaultTheme: ThemeConfig = {
    token: {
        colorPrimary: PRIMARY_COLOR,
        colorLink: PRIMARY_COLOR,
        fontFamily: '"Raleway", sans-serif',
        colorSuccess: SUCCESS_COLOR,
        colorWarning: WARNING_COLOR,
        colorTextBase: BASE_COLOR,
        colorText: BASE_COLOR,
        colorTextSecondary: SECONDARY_COLOR,
    },
    components: {
        Layout: {
            headerBg: '#ffffff',
            bodyBg: '#f1f1f1',
            footerBg: '#f1f1f1',
        },
        Button: {
            controlOutline: 'none',
        },
        Modal: {
            wireframe: true,
        },
        Notification: {
            colorInfo: PRIMARY_COLOR,
        },
        Alert: {
            colorInfo: INFO_COLOR,
            colorInfoBg: 'rgba(82, 175, 250, 0.1)',
            colorInfoBorder: INFO_COLOR,
        },
    },
};

export default defaultTheme;
