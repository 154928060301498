import DownloadOutlined from '@ant-design/icons/DownloadOutlined';
import Alert from 'antd/lib/alert';
import App from 'antd/lib/app';
import Button from 'antd/lib/button';
import Empty from 'antd/lib/empty';
import Row from 'antd/lib/row';
import Skeleton from 'antd/lib/skeleton';
import Space from 'antd/lib/space';
import { endOfYear, startOfYear } from 'date-fns';
import { useState } from 'react';
import { useLocation } from 'react-router-dom';
import { PageHeader } from '../../components/pageHeader/pageHeader';
import { EventStatisticWidget } from '../../components/price-response-reporting/EventStatisticWidget';
import { SavingsWidget } from '../../components/price-response-reporting/SavingsWidget';
import { TriggerPriceWidget } from '../../components/price-response-reporting/TriggerPriceWidget';
import { DatePeriodSelector } from '../../components/selectors/DatePeriodSelector/DatePeriodSelector';
import { useCompanies } from '../../components/selectors/GlobalCompanySelector/CompanyContext';
import { useDocumentTitle } from '../../components/useDocumentTitle';
import { usePriceResponseReportQuery } from '../../domain/price-response/queries';
import { exportPriceResponse } from '../../domain/price-response/reporting';
import { IDateRange } from '../interface';
import { usePageLocation } from '../usePageState';
import './priceResponseReporting.css';

const DEFAULT_PERIOD = {
    start: +startOfYear(new Date()),
    end: +endOfYear(new Date()),
};

export const PriceResponseReporting = () => {
    const { notification } = App.useApp();
    const location = useLocation();
    const { setPageQuery, queryToState } = usePageLocation();
    const pageState: any = queryToState(location.search);

    useDocumentTitle('Price Response Reporting');

    const { company } = useCompanies()!;
    const [exportLoading, setExportLoading] = useState<boolean>(false);
    const [date, setDate] = useState<IDateRange>({ ...DEFAULT_PERIOD, ...(pageState?.date && { ...pageState?.date }) });

    const listQuery = {
        companyId: company!,
        ...(date && {
            start: new Date(date.start),
            end: new Date(date.end),
        }),
    }

    const { data: priceResponseMetrics, isLoading, isError, error } = usePriceResponseReportQuery(listQuery, {
        enabled: !!company,
    });

    if (isError) {
        notification.error({ key: 'price-response-reporting-error', message: error?.message || 'Cannot fetch price response reporting data' });
    }

    const exportPriceResponseAction = async () => {
        try {
            setExportLoading(true);
            await exportPriceResponse({ companyId: company!, start: new Date(date.start), end: new Date(date.end) });
        } catch (error: any) {
            notification.error({ key: 'price-response-export-error', message: error.message || 'Cannot export price response reporting data!' });
        }
        setExportLoading(false);
    };

    const handleDatePeriod = (selectedDate: IDateRange) => {
        setDate(selectedDate);
        setPageQuery({ ...pageState, date: selectedDate });
    };

    const PageContent = () => {
        const Content = priceResponseMetrics ? (
            <>
                <SavingsWidget savings={priceResponseMetrics.savings} />
                <EventStatisticWidget events={priceResponseMetrics.events} />
                {/* <Co2ImpactWidget impact={priceResponseMetrics.impact} /> */}
                {/* <Row gutter={[16, 16]}> */}
                {/* s<SitesEnrollmentWidget sitesMetric={priceResponseMetrics.sites} /> */}
                {/* <Col xs={24} md={12} style={{ padding: 0, display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}> */}
                <TriggerPriceWidget trigger={priceResponseMetrics.trigger} />
                {/*<NavigationBlock />*/}
                {/* </Col> */}
                {/* </Row> */}
            </>
        ) : (
            <Row justify="center">
                <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
            </Row>
        );
        return isLoading ? <Skeleton loading={isLoading} paragraph active /> : Content;
    };

    return (
        <>
            <PageHeader
                pageTitle="Price Response"
                actions={[
                    <DatePeriodSelector
                        key="date-period-selector"
                        data-cy="date-period-selector"
                        onChange={handleDatePeriod}
                        selectedPeriod={date}
                        disabled={!company || isLoading}
                    />,
                    <Button
                        size="large"
                        data-cy="download-csv"
                        key="download-csv"
                        icon={<DownloadOutlined />}
                        onClick={exportPriceResponseAction}
                        loading={exportLoading}
                        disabled={!company}
                    >
                        Export to PDF
                    </Button>,
                ]}
            />
            <Row justify="center">
                <div className="price-response-reporting">
                    {company ? (
                        <PageContent />
                    ) : (
                        <Row justify="center" className="content-background card-style">
                            <Space direction="vertical">
                                <Alert showIcon message=" Please select a company." type="warning" />
                                <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                            </Space>
                        </Row>
                    )}
                </div>
            </Row>
        </>
    );
};
