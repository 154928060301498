import { HistoryOutlined, LinkOutlined } from '@ant-design/icons';
import { TableProps } from 'antd';
import { Card, Empty } from 'antd/lib';
import Button from 'antd/lib/button';
import Collapse from 'antd/lib/collapse';
import Descriptions from 'antd/lib/descriptions';
import Divider from 'antd/lib/divider';
import Flex from 'antd/lib/flex';
import Table from 'antd/lib/table';
import Tag from 'antd/lib/tag';
import Typography from 'antd/lib/typography';
import React, { useState } from 'react';
import { AuditEntityType } from '../../domain/audit-trail/interface';
import { defaultDateFormat, defaultDateFormatInUTC } from '../../domain/common/dateFormatters';
import { PAGE_ACTION } from '../../domain/commonConst';
import { IMeter, IMeterEnrollment, ISan, ISanInfoSource, ISite } from '../../domain/site/interface';
import { AuditTrailModal } from '../modals/AuditTrailModal/AuditTrailModal';
import { SiteFullAddress } from './SiteAddress';
import './extra-site-information.css';
import { PRIMARY_COLOR } from 'src/theme';
import { ActiveSanTag } from './ActiveSanTag';

const MeterInfo = ({ meterList, showAuditTrailModal }: { meterList?: IMeter[]; showAuditTrailModal: Function }) => {
    if (!meterList?.length) return <span>&nbsp;-&nbsp;</span>;

    return (
        <Collapse style={{ width: '100%' }}>
            {meterList.map((meterInfo, index) => {
                const meterEnrollments = meterInfo.meter_enrollments;
                return (
                    <Collapse.Panel
                        key={`${meterInfo.salesforce_id}-${meterInfo.name}-${index}`}
                        header={
                            <Flex justify="space-between" align="center">
                                <Typography.Text
                                    ellipsis={{ tooltip: { title: meterInfo.name } }}
                                    style={{ maxWidth: '500px' }}
                                >
                                    {meterInfo.name}
                                </Typography.Text>
                                <Flex>
                                    <Button
                                        size="small"
                                        type="link"
                                        target={'_blank'}
                                        icon={<LinkOutlined />}
                                        href={meterInfo.salesforce_url}
                                    />
                                    <Button
                                        size="small"
                                        onClick={showAuditTrailModal(
                                            meterInfo.salesforce_id,
                                            AuditEntityType.METER_ENTITY
                                        )}
                                        type="text"
                                        icon={<HistoryOutlined />}
                                    />
                                </Flex>
                            </Flex>
                        }
                    >
                        <Descriptions size="small" column={{ xs: 1, sm: 1, md: 2 }}>
                            <Descriptions.Item label="Meter Salesforce ID">
                                {meterInfo.salesforce_id || '-'}
                            </Descriptions.Item>
                            <Descriptions.Item label="Enrollment ID">
                                {meterInfo.service_account_number || '-'}
                            </Descriptions.Item>
                            <Descriptions.Item label="Store Number">{meterInfo.store_number || '-'}</Descriptions.Item>
                            <Descriptions.Item label="Account Name">
                                {meterInfo?.account?.name || '-'}
                            </Descriptions.Item>
                            <Descriptions.Item label="Site Max Duration">
                                {meterInfo.event_max_duration || '-'}
                            </Descriptions.Item>
                            <Descriptions.Item label="Site Enrolled (kW)">
                                {meterInfo.contract_max_per_site || '-'}
                            </Descriptions.Item>
                            <Descriptions.Item label="Zone SubLAP">{meterInfo.zone_sub_lap || '-'}</Descriptions.Item>
                            <Descriptions.Item label="Address" span={5}>
                                <SiteFullAddress
                                    address={meterInfo.address}
                                    city={meterInfo.city}
                                    state={meterInfo.state}
                                    zip={meterInfo.zip}
                                    country={meterInfo.country}
                                />
                            </Descriptions.Item>
                            <Descriptions.Item label="Salesforce created by">
                                {meterInfo.salesforce_created_by || '-'} /{' '}
                                {defaultDateFormat(meterInfo.salesforce_created_at, '-')}
                            </Descriptions.Item>
                            <Descriptions.Item label="Salesforce updated by">
                                {meterInfo.salesforce_updated_by || '-'} /{' '}
                                {defaultDateFormat(meterInfo.salesforce_updated_at, '-')}
                            </Descriptions.Item>
                        </Descriptions>
                        {!!meterEnrollments?.length && (
                            <>
                                <Divider plain>Meter Enrollments list</Divider>
                                <MeterEnrollmentInfo
                                    enrollmentList={meterEnrollments}
                                    showAuditTrailModal={showAuditTrailModal}
                                />
                            </>
                        )}
                    </Collapse.Panel>
                );
            })}
        </Collapse>
    );
};

const MeterEnrollmentInfo = ({
    enrollmentList,
    showAuditTrailModal,
}: {
    enrollmentList?: IMeterEnrollment[];
    showAuditTrailModal: Function;
}) => {
    if (!enrollmentList?.length) return <span>&nbsp;-&nbsp;</span>;

    return (
        <Collapse style={{ width: '100%' }}>
            {enrollmentList.map((enrollmentInfo, index) => (
                <Collapse.Panel
                    key={`${enrollmentInfo.salesforce_id}-${enrollmentInfo.name}-${index}`}
                    header={
                        <Flex justify="space-between" align="center">
                            <Typography.Text
                                ellipsis={{ tooltip: { title: enrollmentInfo.name } }}
                                style={{ maxWidth: '500px' }}
                            >
                                {enrollmentInfo.name}
                            </Typography.Text>
                            <Button
                                size="small"
                                onClick={showAuditTrailModal(
                                    enrollmentInfo.salesforce_id,
                                    AuditEntityType.METER_ENROLLMENT_ENTITY
                                )}
                                type="text"
                                icon={<HistoryOutlined />}
                            />
                        </Flex>
                    }
                >
                    <Descriptions size="small" column={{ xs: 1, sm: 1, md: 2 }}>
                        <Descriptions.Item label="Enrollment Salesforce ID">
                            {enrollmentInfo.salesforce_id || '-'}
                        </Descriptions.Item>
                        <Descriptions.Item label="Enrollment ID">
                            {enrollmentInfo.enrollment_id || '-'}
                        </Descriptions.Item>
                        <Descriptions.Item label="Product name">{enrollmentInfo.product_name || '-'}</Descriptions.Item>
                        <Descriptions.Item label="Enrollment status">
                            {enrollmentInfo.enrollment_status || '-'}
                        </Descriptions.Item>
                        <Descriptions.Item label="Enrollment start date">
                            {defaultDateFormat(enrollmentInfo.enrollment_start_date, '-')}
                        </Descriptions.Item>
                        <Descriptions.Item label="Enrollment end date">
                            {defaultDateFormat(enrollmentInfo.enrollment_end_date, '-')}
                        </Descriptions.Item>
                        <Descriptions.Item label="Salesforce submitted at">
                            {defaultDateFormat(enrollmentInfo.salesforce_submitted_at, '-')}
                        </Descriptions.Item>
                        <Descriptions.Item label="Salesforce created by">
                            {enrollmentInfo.salesforce_created_by || '-'} /{' '}
                            {defaultDateFormat(enrollmentInfo.salesforce_created_at, '-')}
                        </Descriptions.Item>
                        <Descriptions.Item label="Salesforce updated by">
                            {enrollmentInfo.salesforce_updated_by || '-'} /{' '}
                            {defaultDateFormat(enrollmentInfo.salesforce_updated_at, '-')}
                        </Descriptions.Item>
                    </Descriptions>
                </Collapse.Panel>
            ))}
        </Collapse>
    );
};

const columns: TableProps<ISanInfoSource>['columns'] = [
    {
        title: 'Source',
        dataIndex: 'source',
        sorter: (a, b) => a.source.toLowerCase().localeCompare(b.source.toLowerCase()),
    },
    {
        title: 'Latest Interval Data Date',
        dataIndex: 'latestIntervalDataDate',
        render: (date: string | null) => (date ? defaultDateFormatInUTC(date) : '-'),
        sorter: (a, b) => {
            if (!a.latestIntervalDataDate || !b.latestIntervalDataDate) return 0;

            return new Date(a.latestIntervalDataDate).getTime() - new Date(b.latestIntervalDataDate).getTime();
        },
    },
    {
        title: 'Usage Point',
        dataIndex: 'usagePoint',
        sorter: (a, b) => a.usagePoint.toLowerCase().localeCompare(b.usagePoint.toLowerCase()),
    },
    {
        title: 'Retail Customer',
        dataIndex: 'retailCustomer',
        sorter: (a, b) => a.retailCustomer.toLowerCase().localeCompare(b.retailCustomer.toLowerCase()),
    },
    {
        title: 'Retail Customer Name',
        dataIndex: 'customerName',
        sorter: (a, b) => a.customerName.toLowerCase().localeCompare(b.customerName.toLowerCase()),
    },
    {
        title: 'Agreement Status',
        dataIndex: ['agreement', 'status'],
        render: (status: string | null) =>
            status ? status.toLowerCase() === 'active' ? <Tag color={PRIMARY_COLOR}>Active</Tag> : status : '-',
    },
    {
        title: 'Agreement Start Date',
        dataIndex: ['agreement', 'startAt'],
        render: (date: string | null) => (date ? defaultDateFormat(date) : '-'),
        sorter: (a, b) => {
            if (!a.latestIntervalDataDate || !b.latestIntervalDataDate) return 0;

            return new Date(a.latestIntervalDataDate).getTime() - new Date(b.latestIntervalDataDate).getTime();
        },
    },
    {
        title: 'Agreement End Date',
        dataIndex: ['agreement', 'endAt'],
        render: (date: string | null) => (date ? defaultDateFormat(date) : '-'),
        sorter: (a, b) => {
            if (!a.latestIntervalDataDate || !b.latestIntervalDataDate) return 0;

            return new Date(a.latestIntervalDataDate).getTime() - new Date(b.latestIntervalDataDate).getTime();
        },
    },
];

const DataSourcesInfo = ({ sans }: { sans: ISan[] }) => {
    return (
        <>
            <Collapse style={{ width: '100%' }}>
                {sans.map(san => {
                    const sanInfoSources = san.san_info?.sources || [];

                    return (
                        <Collapse.Panel
                            key={san.service_account_number}
                            header={
                                <>
                                    <Typography.Text style={{ marginRight: 8 }}>
                                        Enrollment ID: {san.service_account_number}
                                    </Typography.Text>
                                    <ActiveSanTag isActive={san.is_active} />
                                </>
                            }
                        >
                            <Table
                                size="small"
                                rowHoverable={false}
                                columns={columns}
                                dataSource={sanInfoSources}
                                pagination={false}
                                className="extra-site-information-table"
                            />
                        </Collapse.Panel>
                    );
                })}
            </Collapse>
        </>
    );
};

export const ExtraSiteInformation = ({ site }: { site: ISite }) => {
    const { meters, sans } = site;

    const [pageAction, setPageAction] = useState<PAGE_ACTION | ''>();
    const [selectedEntityType, setSelectedEntityType] = useState<
        AuditEntityType.METER_ENTITY | AuditEntityType.METER_ENROLLMENT_ENTITY | null
    >();
    const [selectedEntitySalesforceId, setSelectedEntitySalesforceId] = useState<string | null>();

    const showAuditTrailModal =
        (salesforceId: string, entityType: AuditEntityType.METER_ENTITY | AuditEntityType.METER_ENROLLMENT_ENTITY) =>
        (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
            event.stopPropagation();

            setPageAction(PAGE_ACTION.AUDIT_TRAIL);
            setSelectedEntityType(entityType);
            setSelectedEntitySalesforceId(salesforceId);
        };

    const closeAuditTrailModal = () => {
        setPageAction('');
        setSelectedEntityType(null);
        setSelectedEntitySalesforceId(null);
    };

    if (!meters?.length && !sans.length) {
        return <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />;
    }

    return (
        <>
            <Card className="extra-site-information" size="small" style={{ padding: '0 16px 16px 16px' }}>
                {!!meters?.length && (
                    <>
                        <Divider plain>Meters list</Divider>
                        <MeterInfo meterList={meters} showAuditTrailModal={showAuditTrailModal} />
                    </>
                )}
                {!!sans.length && (
                    <>
                        <Divider plain>Data Sources Info</Divider>
                        <DataSourcesInfo sans={sans} />
                    </>
                )}
            </Card>
            {selectedEntitySalesforceId && selectedEntityType && pageAction === PAGE_ACTION.AUDIT_TRAIL && (
                <AuditTrailModal
                    entityId={selectedEntitySalesforceId}
                    entityType={selectedEntityType}
                    onClose={closeAuditTrailModal}
                />
            )}
        </>
    );
};
