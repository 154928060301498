import { useInfiniteQuery, useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { IMergeSitesReqDto } from '../../components/site/merge-sites/interface';
import { usePrevious } from '../../components/usePrevious';
import { QueryKey } from '../../query-client';
import { IPagination } from '../IPagination';
import {
    createSite,
    deleteSite,
    enrollSiteToPrograms,
    fetchAllMeters,
    fetchLocations,
    fetchSite,
    fetchSiteLabels,
    fetchSites,
    fetchSiteUtilities,
    getSiteSanInfoSources,
    mergeSites,
    updateSite,
    updateSiteSans,
} from './index';
import {
    ICreateSite,
    IEnrollSiteToPrograms,
    IFetchLocationsQuery,
    IFetchSiteLabelsQuery,
    IFetchSitePageQuery,
    IMeter,
    ISan,
    ISanFormItem,
    ISite,
    ISiteLocation,
    IUpdateSite,
} from './interface';
import { IFetchPageQuery } from '../IFetchQueryOptions';

const PAGE_SIZE = 10000;

export const useSiteInfiniteListQuery = (query: IFetchSitePageQuery, options = {}) => {
    const companyId = query.company_id;
    const prevCompanyId = usePrevious(companyId);

    const {
        data: res,
        isFetching,
        fetchNextPage,
    } = useInfiniteQuery<IPagination<ISite>, Error>({
        queryKey: [
            QueryKey.SITES,
            {
                pagination: { pageSize: PAGE_SIZE, current: 1 },
                filter: { company_id: companyId }
            },
        ],
        queryFn: ({ pageParam = 1 }) =>
            fetchSites({ pagination: { pageSize: PAGE_SIZE, current: pageParam }, filter: { company_id: companyId } }),
        getNextPageParam: (lastPage, pages) => {
            if (lastPage.meta.total > pages.length) {
                return pages.length + 1;
            }
        },
        getPreviousPageParam: (firstPage, pages) => {
            if (firstPage.meta.total > pages.length) {
                return pages.length + 1;
            }
        },
        keepPreviousData: prevCompanyId === companyId,
        ...options,
    });

    return {
        data: res?.pages.map(page => page.data).flat() || [],
        meta: res?.pages[0]?.meta || { total: 0 },
        fetchNextPage,
        isFetching,
    };
};

interface ISiteQueryOptions {
    include?: string;

    [key: string]: any;
}

export const useSiteListQuery = (query: IFetchSitePageQuery, options = {}) => {
    return useQuery<IPagination<ISite>, Error>({
        queryKey: [QueryKey.SITES, query],
        queryFn: () => fetchSites(query),
        ...options,
    });
};

export const useSiteUtilitiesListQuery = (options = {}) => {
    return useQuery<string[], Error>({
        queryKey: [QueryKey.SITE_UTILITIES, null],
        queryFn: () => fetchSiteUtilities(),
        ...options,
    });
};

export const useSiteQuery = (siteId?: number, options: ISiteQueryOptions = {}) => {
    return useQuery<ISite | null, Error>({
        queryKey: [QueryKey.SITES, siteId],
        queryFn: () => fetchSite(siteId!, options?.include),
        enabled: !!siteId,
        ...options,
    });
};

export const useSiteSanInfoSourceListQuery = (siteId: number | null, sans: string[]) => {
    return useQuery({
        queryKey: [QueryKey.SITE_SAN_INFO, sans],
        queryFn: () => getSiteSanInfoSources(siteId!),
        enabled: !!siteId,
    });
};

export const useSiteLabelListQuery = (query: IFetchSiteLabelsQuery, options = {}) => {
    return useQuery({
        queryKey: [QueryKey.SITE_LABELS, query],
        queryFn: () => fetchSiteLabels(query),
        ...options,
    });
};

export const useUpdateSiteSansMutation = () => {
    const queryClient = useQueryClient();

    return useMutation<ISan[], Error, { siteId: number; sans: ISanFormItem[] }>({
        mutationFn: ({ siteId, sans }) => updateSiteSans(siteId, sans),
        onSuccess: async (_, variables) => {
            await queryClient.invalidateQueries([QueryKey.SITE_SAN_INFO, variables.siteId]);
        },
    });
};

export const useSiteCreateMutation = () => {
    return useMutation<ISite, Error, ICreateSite>({
        mutationFn: createSiteData => createSite(createSiteData),
    });
};

export const useSiteUpdateMutation = () => {
    return useMutation<ISite, Error, IUpdateSite>({
        mutationFn: site => updateSite(site),
    });
};

export const useSiteDeleteMutation = () => {
    return useMutation<void, Error, ISite>({
        mutationFn: site => deleteSite(site),
    });
};

export const useSiteEnrollProgramsMutation = () => {
    return useMutation<void, Error, { site: ISite; enrollments: IEnrollSiteToPrograms }>({
        mutationFn: ({ site, enrollments }) => enrollSiteToPrograms(site, enrollments),
    });
};

export const useSiteLocationListQuery = (query: IFetchLocationsQuery, options = {}) => {
    return useQuery<ISiteLocation[], Error>({
        queryKey: [QueryKey.SITE_LOCATIONS, query],
        queryFn: () => fetchLocations(query),
        ...options,
    });
};

export const useMergeSitesMutation = () => {
    return useMutation<ISite, Error, IMergeSitesReqDto>({
        mutationFn: data => mergeSites(data),
        retry: 0, // TODO: remove this when we have a proper error handling
    });
};

export const useAllMetersListQuery = (query: IFetchPageQuery, options = {}) => {
    return useQuery<IMeter[], Error>([QueryKey.METERS, query], ({ signal }) => fetchAllMeters(query, signal), {
        ...options,
        keepPreviousData: true,
    });
};
