import format from 'date-fns/format';
import omit from 'lodash/omit';
import isNil from 'lodash/isNil';
import { API } from '../api';
import { IPagination } from '../IPagination';
import {
    ICloudVen,
    ICustomerVen,
    IFetchVenPageQuery,
    IVenOverview,
    ICloudVenCertificateDetails,
    ICustomerVenControlProviders,
} from './interface';
import { DATE_FORMAT_CSV } from '../commonConst';
import { exportCSVData } from '../common/exportCSVData';
import { IFetchPageQuery } from '../IFetchQueryOptions';

export const fetchVenOverview = async (companyId?: number): Promise<IVenOverview> => {
    const query = companyId ? `?${new URLSearchParams({ company_id: companyId.toString() })}` : '';
    const response = await API.fetch(`/devices/overview${query}`, {
        method: 'get',
        headers: {
            'Content-Type': 'application/json',
        },
    });
    const respBody: any = await response.json();

    if (response.ok) {
        return respBody;
    }

    throw new Error(respBody?.error?.message || `Cannot get ven overview!`);
};

//
// CLOUD VENS
//
export const fetchCloudVens = async (
    { pagination, sorter, search, filter, status, hideRelatedToClosedSites }: IFetchVenPageQuery,
    signal?: AbortSignal | null
): Promise<IPagination<ICloudVen>> => {
    const limit = pagination?.pageSize || 10;
    const offset = (pagination?.current ? pagination.current - 1 : 0) * limit;

    if (sorter && (!sorter.field || !sorter.order)) {
        sorter = {};
    }

    if (filter && isNil(filter.program_id)) {
        delete filter.program_id;
    }

    const path = `/cloud-ven?${new URLSearchParams({
        offset: offset.toString(),
        limit: limit.toString(),
        ...(sorter?.field && { sortField: sorter.field, sortOrder: sorter.order }),
        ...filter,
        ...(search && { search }),
        ...(status && { status }),
        ...(hideRelatedToClosedSites && { hideRelatedToClosedSites: hideRelatedToClosedSites.toString() }),
    })}`;

    const response = await API.fetch(path, {
        ...(signal && { signal }),
        headers: {
            'Content-Type': 'application/json',
        },
    });

    const respBody: any = await response.json();

    if (response.ok) {
        return respBody;
    }

    throw new Error(respBody?.error?.message || `Cannot get cloud VENs!`);
};

const updateCloudVen = async (ven: ICloudVen) => {
    const response = await API.fetch(`/cloud-ven/${ven.id}`, {
        method: 'put',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(omit(ven, ['id'])),
    });

    const respBody: any = await response.json();
    if (response.ok) {
        return respBody;
    }

    throw new Error(respBody?.error?.message || `Cannot save VEN!`);
};

const createCloudVen = async (ven: ICloudVen) => {
    const response = await API.fetch(`/cloud-ven`, {
        method: 'post',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(ven),
    });

    const respBody: any = await response.json();
    if (response.ok) {
        return respBody;
    }

    throw new Error(respBody?.error?.message || `Cannot save VEN!`);
};

export const saveCloudVen = async (ven: ICloudVen): Promise<ICloudVen> => {
    return ven.id ? await updateCloudVen(ven) : await createCloudVen(ven);
};

export const deleteCloudVen = async (cloudVen: ICloudVen) => {
    const response = await API.fetch(`/cloud-ven/${cloudVen.id}`, {
        method: 'delete',
        headers: {
            'Content-Type': 'application/json',
        },
    });

    if (response.ok) {
        return;
    }

    const respBody: any = await response.json();
    throw new Error(respBody?.error?.message || `Cannot delete cloud VEN!`);
};

export const exportCloudVens = async ({ search, status }: any) => {
    const path = `/cloud-ven?${new URLSearchParams({
        format: 'csv',
        ...(search && { search }),
        ...(status && { status }),
    })}`;

    const response = await API.fetch(path, {
        headers: {
            'Content-Type': 'text/csv',
        },
    });

    const respBody: string = await response.text();

    if (response.ok) {
        const fileName = `cloudVens--${format(new Date(), DATE_FORMAT_CSV)}.csv`;
        exportCSVData(respBody, fileName);
    } else {
        const respBody: any = await response.json();
        throw new Error(respBody?.error?.message || `Cannot export data!`);
    }
};

export const getCloudVenCertificateDetails = async (certificateText: string): Promise<ICloudVenCertificateDetails> => {
    const response = await API.fetch('/cloud-ven/certificate-details', {
        method: 'post',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            certText: certificateText,
        }),
    });

    const respBody: any = await response.json();

    if (response.ok) {
        return respBody;
    }

    throw new Error(respBody?.error?.message || `Cannot get cloud VEN certificate details!`);
};

//
// CUSTOMER VEN
//

export const fetchCustomerVens = async (
    {
        pagination,
        sorter,
        search,
        status,
        company_id = null,
        type,
        hideRelatedToClosedSites,
        control_providers_id,
    }: IFetchVenPageQuery,
    signal?: AbortSignal | null
): Promise<IPagination<ICustomerVen>> => {
    const limit = pagination?.pageSize || 10;
    const offset = (pagination?.current ? pagination.current - 1 : 0) * limit;

    if (sorter && (!sorter.field || !sorter.order)) {
        sorter = {};
    }

    const path = `/customer-ven?${new URLSearchParams({
        ...(sorter?.field && { sortField: sorter.field, sortOrder: sorter.order }),
        ...(search && { search }),
        ...(status && { status }),
        ...(type && { type }),
        ...(company_id && { company_id }),
        ...(hideRelatedToClosedSites && { hideRelatedToClosedSites: hideRelatedToClosedSites.toString() }),
        ...(control_providers_id && { control_providers_id }),
        offset: offset.toString(),
        limit: limit.toString(),
    })}`;

    const response = await API.fetch(path, {
        ...(signal && { signal }),
        headers: {
            'Content-Type': 'application/json',
        },
    });

    const respBody: any = await response.json();

    if (response.ok) {
        return respBody;
    }

    throw new Error(respBody?.error?.message || `Cannot get customer VENs!`);
};

export const fetchCustomerVensControlProviders = async (
    { company_id }: IFetchPageQuery,
    signal?: AbortSignal | null
): Promise<ICustomerVenControlProviders[]> => {
    const path = `/customer-ven/control-providers?${new URLSearchParams({
        ...(company_id && { company_id }),
    })}`;

    const response = await API.fetch(path, {
        ...(signal && { signal }),
        headers: {
            'Content-Type': 'application/json',
        },
    });

    const respBody: any = await response.json();

    if (response.ok) {
        return respBody;
    }

    throw new Error(respBody?.error?.message || `Cannot get customer VENs Control Providers!`);
};

export const exportCustomerVens = async (
    { search, status, company_id = null, type }: IFetchVenPageQuery,
    fileName: string
) => {
    const path = `/customer-ven?${new URLSearchParams({
        format: 'csv',
        ...(type && { type }),
        ...(search && { search }),
        ...(status && { status }),
        ...(company_id && { company_id }),
    })}`;

    const response = await API.fetch(path, {
        headers: {
            'Content-Type': 'text/csv',
        },
    });

    const respBody: string = await response.text();

    if (response.ok) {
        exportCSVData(respBody, fileName);
    } else {
        const respBody: any = await response.json();
        throw new Error(respBody?.error?.message || `Cannot export data!`);
    }
};

export const deleteCustomerVen = async (customerVen: ICustomerVen) => {
    const response = await API.fetch(`/customer-ven/${customerVen.id}`, {
        method: 'delete',
        headers: {
            'Content-Type': 'application/json',
        },
    });

    if (response.ok) {
        return;
    }

    const respBody: any = await response.json();
    throw new Error(respBody?.error?.message || `Cannot delete customer VEN!`);
};

const createCustomerVen = async (ven: ICustomerVen): Promise<ICustomerVen> => {
    const response = await API.fetch(`/customer-ven`, {
        method: 'post',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(ven),
    });

    const respBody: any = await response.json();
    if (response.ok) {
        return respBody;
    }

    throw new Error(respBody?.error?.message || `Cannot save VEN!`);
};

const updateCustomerVen = async (ven: ICustomerVen): Promise<ICustomerVen> => {
    const response = await API.fetch(`/customer-ven/${ven.id}`, {
        method: 'put',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(omit(ven, ['id'])),
    });

    const respBody: any = await response.json();
    if (response.ok) {
        return respBody;
    }

    throw new Error(respBody?.error?.message || `Cannot save VEN!`);
};

export const saveCustomerVen = async (ven: ICustomerVen) => {
    return ven.id ? await updateCustomerVen(ven) : await createCustomerVen(ven);
};
