import { IAccessPartner } from '../access-partner/interface';
import { ICompany } from '../company/interface';
import { IProduct } from '../product/interface';
import { IProgramType } from '../program-type/interface';

export enum ReviewStatus {
    NOT_LINKED = 'not_linked',
    REVIEW_NEEDED = 'review_needed',
    LINKED = 'linked',
    APPROVED = 'approved',
}

export enum ProgramUtilityAutomation {
    APX = 'email:APX',
    ENBALA = 'email:Enbala',
    EVERGY = 'email:Evergy',
    CPSENERGY = 'email:CPSEnergy',
    EVERBRIDGE = 'email:Everbridge',
    TFCCIONCOR = 'email:TFCCIOncor',
    CPOWERENERGY = 'email:CPowerEnergy',
    NRG_ACTIVATIONS = 'email:NRGActivation',
    ENERSPONSE_INTERNAL = 'email:InternalTest',
    SCE = 'email:SCE',
    VOLTUS = 'email:Voltus',
    PUGET_SOUND_ENEL = 'email:pugetSoundEnel',
    ROCKY_MOUNTAIN_ENEL = 'email:rockyMountainEnel',
}

export interface IProgram {
    program_id: number;
    name: string;
    iso?: string;
    utility_id?: string;
    utility_name?: string;
    energy_market_id?: string;
    type_id?: string;
    type?: IProgramType;
    access_partner_id?: string;
    access_partner?: IAccessPartner;
    description: string;

    utility_customer_id?: number;
    utility_customer?: ICompany;

    utility_automation?: ProgramUtilityAutomation;
    program_locked: boolean;

    enrolled_sites_count?: number;
    products?: IProduct[];

    date_created: Date;
    date_updated: Date;
}

export interface IUniqueProgramOperator {
    text: string;
    value: string;
}

export interface IUniqueProgramType {
    text: string;
    value: string;
}

export interface ICreateProgram {
    name: string;
    iso?: string;
    utility_id?: string;
    access_partner_id?: string | null;
    type_id?: string | null;
    description?: string;
    utility_customer_id?: number;
    utility_automation?: ProgramUtilityAutomation | null;

    product_ids?: string[];
}

export interface IUpdateProgram {
    program_id: number;

    name: string;
    // utility_customer_id?: number;
    iso?: string;
    utility_id?: string;
    access_partner_id?: string | null;
    type_id?: string | null;
    description?: string;
    utility_automation?: ProgramUtilityAutomation | null;

    product_ids?: string[];
}
