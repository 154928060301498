import React, { useEffect, useMemo, useState } from 'react';
import Select from 'antd/lib/select';
import Typography from 'antd/lib/typography';
import Form from 'antd/lib/form';
import uniqBy from 'lodash/uniqBy';
import isEqual from 'lodash/isEqual';
import { usePrevious } from '../usePrevious';
import { useSiteLabelListQuery } from '../../domain/site/queries';

interface ISiteLabelsSectionProps {
    siteLabels: string[];
    setSiteLabels: (siteLabels: string[]) => void;
    companyId: number;
    allowCreate?: boolean;
}

interface ISelectOption {
    label?: string;
    value: string;
}

function transformSiteLabelToOption(value: string) {
    const siteLabel = value.trim();

    return { value: siteLabel, label: siteLabel };
}

export const SiteLabelsSection: React.FC<ISiteLabelsSectionProps> = ({ siteLabels, setSiteLabels, companyId, allowCreate = false }) => {
    const prevCompanyId = usePrevious(companyId);
    const [selectedOptions, setSelectedOptions] = useState<ISelectOption[]>([]);

    const { data, isLoading } = useSiteLabelListQuery({ companyId }, {});
    const loadedOptions = useMemo(() => {
        if (!data) return [];

        if (prevCompanyId && companyId !== prevCompanyId) {
            return [];
        }

        return data.site_labels.map(transformSiteLabelToOption);
    }, [data, companyId, prevCompanyId]);

    const handleChange = (updatedOptions: ISelectOption | ISelectOption[]) => {
        updatedOptions = Array.isArray(updatedOptions) ? updatedOptions : [updatedOptions];

        updatedOptions = updatedOptions.map(({ value }) => transformSiteLabelToOption(value));
        updatedOptions = uniqBy(updatedOptions, 'value');

        setSelectedOptions(updatedOptions);
    };

    useEffect(() => {
        const updatedSiteLabels = selectedOptions.map(({ value }) => value);

        if (isEqual(updatedSiteLabels, siteLabels)) return;

        setSiteLabels(updatedSiteLabels);
    }, [selectedOptions]);

    // load initial values
    useEffect(() => {
        const siteLabelsAsOptions = siteLabels.map(transformSiteLabelToOption);

        if (isEqual(siteLabelsAsOptions, selectedOptions)) return;

        setSelectedOptions(siteLabelsAsOptions);
    }, []);

    return (
        <Form.Item
            label={<Typography.Text strong>Labels</Typography.Text>}
            tooltip="Used for additional grouping and filtering email event notifications"
        >
            <Select
                allowClear
                labelInValue
                mode={allowCreate ? 'tags' : 'multiple'}
                loading={isLoading}
                size="large"
                placeholder={allowCreate ? 'Select or add label' : 'Select label'}
                value={selectedOptions}
                onChange={handleChange}
                style={{ width: '100%' }}
                options={loadedOptions}
            />
        </Form.Item>
    );
};
