import { useState } from 'react';
import TreeSelect from 'antd/lib/tree-select';
import Tag from 'antd/lib/tag';
import Typography from 'antd/lib/typography';
import { toAllowedStringLength } from '../../../domain/common/formattersToAllowedValueLength';
import { ICompany } from '../../../domain/company/interface';
import { fetchSites } from '../../../domain/site';
import { useCompanies } from '../GlobalCompanySelector/CompanyContext';
import { ISite } from '../../../domain/site/interface';

interface ICompanySitesSelector {
    ids?: string | null;
    onChange: Function;
    companyListTransformer?: Function;
}

const generateTreeNode = (parentId: number, node: ISite) => ({
    id: `${node.site_resource_id}::${parentId}::${node.site_id}`,
    value: `${node.site_resource_id}::${parentId}::${node.site_id}`,
    pId: parentId,
    title: `${node.site_resource_id}(${toAllowedStringLength(node.site_name, 25)})`,
    isLeaf: true,
});

const prepareSiteOptions = (sites: ISite[], companyId: number) => {
    return sites.filter(site => site.site_resource_id).map(site => generateTreeNode(companyId, site));
};

const defaultCompanyListTransformer = (companies: ICompany[]) => companies;

export const VenResourceSelector = ({ ids, onChange, companyListTransformer = defaultCompanyListTransformer }: ICompanySitesSelector) => {
    const [selectedResourceIds, setSelectedResourceIds] = useState<string[]>(ids ? ids.split(',').map(id => id.trim()) : []);

    const { companies } = useCompanies()!;
    const preparedCompanies = companyListTransformer(companies);
    const companiesLists = preparedCompanies.map((company: ICompany) => ({
        id: company.company_id,
        value: company.company_id,
        checkable: false,
        selectable: false,
        title: toAllowedStringLength(company.company_name, 50),
    }));
    const [options, setOptions] = useState<any[]>(companiesLists);

    const handleLoadData = async (companyNode: any) => {
        const { data } = await fetchSites({
            pagination: { pageSize: 20000, current: 1 },
            filter: { company_id: companyNode.id },
        });
        const option = prepareSiteOptions(data, +companyNode.value!);
        setOptions([...options, ...option]);
    };

    const handleChange = (value: string[]) => {
        value = value || [];
        const parsed = value.map(val => val.split('::')[0]);
        setSelectedResourceIds(parsed);
        onChange(value);
    };

    const handleRemoveResourceId = (removedResource: string) => {
        const updatedSelectedResourceIds = selectedResourceIds.filter(recource => recource !== removedResource);
        setSelectedResourceIds(updatedSelectedResourceIds);
        onChange(updatedSelectedResourceIds);
    };

    const renderVenResourceIds = () => {
        if (!selectedResourceIds.length) return <></>;
        return (
            <Typography.Paragraph>
                {selectedResourceIds.map(resource => (
                    <Tag key={resource} closable onClose={() => handleRemoveResourceId(resource)}>
                        {resource}
                    </Tag>
                ))}
            </Typography.Paragraph>
        );
    };

    return (
        <div style={{ width: '100%' }}>
            <>{renderVenResourceIds()}</>
            <TreeSelect
                placeholder="Resource ID"
                style={{ width: '100%' }}
                treeDataSimpleMode
                treeCheckable
                allowClear
                size="large"
                multiple
                showSearch={false}
                maxTagCount={2}
                loadData={handleLoadData}
                onChange={handleChange}
                treeData={options}
                tagRender={props => <Tag>{props.label}</Tag>}
                maxTagPlaceholder={props => <Tag>{`+${props.length}...`}</Tag>}
            />
        </div>
    );
};
