export enum SiteImportTableIndexes {
    COMPANY_NAME = 0,
    CONTROL_PROVIDER_NAME = 1,
    UTILITY_CUSTOMER_NAME = 2,
    PROGRAMS = 3,
    SITE_NAME = 4,
    SITE_STORE_NUMBER = 5,
    SITE_ADDRESS = 6,
    SITE_CITY = 7,
    SITE_STATE = 8,
    SITE_ZIP = 9,
    SITE_MARKET = 10,
    SITE_LOAD_ZONE = 11,
    SITE_ESTIMATED_KW = 12,
    SITE_SERVICE_ACCOUNT_NUMBER = 13,
    SITE_UTILITY = 14,
    SITE_SLAP = 15,
    SITE_NETWORK = 16,
    EVENT_OFFSET = 17,
    EVENT_MAX_DURATION = 18,
    CREATE_VEN = 19,
    PARTNER = 20,
}
