import isAfter from 'date-fns/isAfter';
import add from 'date-fns/add';
import format from 'date-fns/format';
import subMinutes from 'date-fns/subMinutes';
import isNil from 'lodash/isNil';
import { API } from '../api';
import {
    IEvent,
    IEventMetrics,
    IEventsCountBySiteAndYears,
    IExportEventsRequestParams,
    IFetchEventsRequestParams,
    IFetchResourcesRequestParams,
} from './interface';
import { IResource } from './resource';
import { IPagination } from '../IPagination';
import { ISite } from '../site/interface';
import { DATE_FORMAT_CSV } from '../commonConst';
import { exportCSVData } from '../common/exportCSVData';
import { IFetchAlertTrackQuery } from '../alert-track/interface';

export const fetchEvents = async (
    params: IFetchEventsRequestParams,
    signal: AbortSignal | null = null
): Promise<IPagination<IEvent>> => {
    updateMarketZoneFilter(params);

    let {
        companyId = null,
        programId = null,
        site_utility = null,
        event_completed,
        siteId = null,
        eventProgramName = null,
        event_cancelled,
        event_opt_type,
        start,
        end,
        startInRange,
        pagination = { pageSize: 10000, current: 1 },
        include,
        sorter,
        search,
        load_zone,
        market,
    } = params;

    const limit = pagination.pageSize!;
    const offset = (pagination.current! - 1) * limit;

    if (sorter && (!sorter.field || !sorter.order)) {
        sorter = {};
    }

    const path = `/events?${new URLSearchParams({
        ...(companyId && { companyId }),
        ...(programId && { programId }),
        ...(site_utility && { site_utility }),
        ...(siteId && { siteId }),
        ...(eventProgramName && { eventProgramName }),
        ...(include && { include }),
        ...(search && { search }),
        ...(load_zone && { load_zone }),
        ...(market && { market }),
        ...(sorter?.field && { sortField: sorter.field, sortOrder: sorter.order }),
        ...(!isNil(event_cancelled) && { event_cancelled }),
        ...(!isNil(event_completed) && { event_completed }),
        ...(!isNil(event_opt_type) && { event_opt_type }),
        ...(start && { start: start.toISOString() }),
        ...(end && { end: end.toISOString() }),
        ...(startInRange && { startInRange }),
        offset: offset.toString(),
        limit: limit.toString(),
    })}`;

    const response = await API.fetch(path, {
        ...(signal && { signal }),
        headers: {
            'Content-Type': 'application/json',
        },
    });

    const respBody: any = await response.json();
    if (response.ok) {
        return respBody;
    }
    throw new Error(response.statusText);
};

export const deleteEvent = async (eventId: number) => {
    const response = await API.fetch(`/events/${eventId}`, {
        method: 'delete',
        headers: {
            'Content-Type': 'application/json',
        },
    });

    if (response.ok) {
        return;
    }

    const respBody: any = await response.json();
    throw new Error(respBody?.error?.message || `Cannot delete event!`);
};

export const updateEvent = async (eventId: number, event: Partial<IEvent>) => {
    const response = await API.fetch(`/events/${eventId}`, {
        method: 'put',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(event),
    });
    const respBody: any = await response.json();

    if (response.ok) {
        return;
    }
    throw new Error(respBody?.error?.message || `Cannot update event!`);
};

export const fetchEventsBatch = async ({
    batchId,
    include,
    companyId,
}: {
    batchId: string;
    include?: string;
    companyId?: number;
}) => {
    const query = `?${new URLSearchParams({
        ...(include && { include }),
        ...(companyId && { companyId: companyId + '' }),
    })}`;
    const response = await API.fetch(`/events/batch/${batchId}${query}`, {
        headers: {
            'Content-Type': 'application/json',
        },
    });

    const respBody: any = await response.json();
    if (response.ok) {
        return respBody;
    }

    throw new Error(response.statusText);
};

export const createEventBatch = async (event: any, sites: number[], companyId?: number) => {
    const query = new URLSearchParams({ ...(companyId && { companyId: companyId + '' }) });
    const response = await API.fetch(`/events/sites/batch?${query}`, {
        method: 'post',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({ event, sites }),
    });

    if (response.ok) {
        return;
    }

    const respBody: any = await response.json();
    throw new Error(respBody?.error?.message || `Cannot save event!`);
};

export const createEventBatchByProgram = async (event: any, programs: number[]) => {
    const response = await API.fetch('/events/programs/batch', {
        method: 'post',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({ event, programs }),
    });

    if (response.ok) {
        return;
    }

    const respBody: any = await response.json();
    throw new Error(respBody?.error?.message || `Cannot save event!`);
};

export const updateEventBatch = async (eventBatchId: string, event: any, companyId?: number) => {
    const query = `?${new URLSearchParams({ ...(companyId && { companyId: companyId + '' }) })}`;
    const response = await API.fetch(`/events/batch/${eventBatchId}${query}`, {
        method: 'put',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(event),
    });

    if (response.ok) {
        return;
    }

    const respBody: any = await response.json();
    throw new Error(respBody?.error?.message || `Cannot update batch!`);
};

export const deleteEventBatch = async (eventBatchId: string, companyId?: number) => {
    const query = `?${new URLSearchParams({ ...(companyId && { companyId: companyId + '' }) })}`;
    const response = await API.fetch(`/events/batch/${eventBatchId}${query}`, {
        method: 'delete',
        headers: {
            'Content-Type': 'application/json',
        },
    });

    if (response.ok) {
        return;
    }

    const respBody: any = await response.json();
    throw new Error(respBody?.error?.message || `Cannot delete event!`);
};

export const exportEvents = async (params: IExportEventsRequestParams) => {
    let {
        start,
        end,
        startInRange,
        sorter,
        include,
        search,
        event_cancelled,
        event_completed,
        event_opt_type,
        companyId,
        programId,
        eventProgramName,
        load_zone,
        market,
        site_utility = null,
    } = params;
    updateMarketZoneFilter(params);

    sorter = sorter || { field: 'event_datetime_start', order: 'descend' };

    if (sorter && (!sorter.field || !sorter.order)) {
        sorter = {};
    }

    const path = `/events?${new URLSearchParams({
        format: 'csv',
        ...(companyId && { companyId: companyId.toString() }),
        ...(programId && { programId }),
        ...(eventProgramName && { eventProgramName }),
        ...(include && { include }),
        ...(search && { search }),
        ...(sorter?.field && { sortField: sorter.field, sortOrder: sorter.order }),
        ...(start && { start: start.toISOString() }),
        ...(end && { end: end.toISOString() }),
        ...(startInRange && { startInRange: startInRange.toString() }),
        ...(!isNil(event_cancelled) && { event_cancelled: event_cancelled.toString() }),
        ...(!isNil(event_completed) && { event_completed: event_completed.toString() }),
        ...(!isNil(event_opt_type) && { event_opt_type: event_opt_type.toString() }),
        ...(load_zone && { load_zone }),
        ...(market && { market }),
        ...(site_utility && { site_utility }),
    })}`;
    const response = await API.fetch(path, {
        headers: {
            'Content-Type': 'text/csv',
        },
    });

    if (response.ok) {
        const respBody: string = await response.text();
        const period =
            start && end
                ? `${start.toISOString().split('T')[0]}_${end.toISOString().split('T')[0]}`
                : `all--${format(new Date(), DATE_FORMAT_CSV)}`;
        const filename = `events-${period}.csv`;
        exportCSVData(respBody, filename);
    } else {
        const respBody: any = await response.json();
        throw new Error(respBody?.error?.message || `Cannot export data!`);
    }
};

export const NO_MARKET_TEXT = 'NO MARKET';
export const NO_LOAD_ZONE_TEXT = 'NO LOAD ZONE';

export const fetchResources = async (
    params: IFetchResourcesRequestParams,
    signal: AbortSignal | null = null
): Promise<IResource[]> => {
    updateMarketZoneFilter(params);

    const { companyId = null, programId = null, search, load_zone, market } = params;

    const path = `/sites?${new URLSearchParams({
        ...(companyId && { company_id: companyId }),
        ...(programId && { program_id: programId }),
        ...(search && { search }),
        ...(load_zone && { load_zone }),
        ...(market && { market }),
        isClosed: false,
        offset: 0,
        limit: 10000,
        include: 'company,program',
    })}`;

    const response = await API.fetch(path, {
        ...(signal && { signal }),
        headers: {
            'Content-Type': 'application/json',
        },
    });
    const respBody: any = await response.json();
    if (response.ok) {
        const resources = respBody.data
            .filter((site: ISite) => !site.closed_at)
            .map((site: ISite) => {
                const resource: IResource = {
                    id: `${site.site_id}`,
                    company: site.company?.company_name!,
                    programs: site.programs.map(p => p.name),
                    site: site.site_name,
                    zip: site.site_zip,
                    utility: site.site_utility || null,
                    event_offset: site.event_offset,
                    event_max_duration: site.event_max_duration || 0,
                    site_state: site.site_state,
                    sans: site.sans.filter(san => san.is_active).map(san => san.service_account_number),
                    site_address: site.site_address,
                    lmp_market: site.lmp_market?.toUpperCase() || NO_MARKET_TEXT,
                    site_load_zone: site.site_load_zone?.toUpperCase() || NO_LOAD_ZONE_TEXT,
                };
                return resource;
            });

        return resources;
    }

    throw new Error(respBody?.error?.message || 'Cannot fetch resources');
};

function updateMarketZoneFilter(filter: {
    load_zone?: string;
    market?: string | null;
    [key: string]: any;
}): IFetchEventsRequestParams {
    if (filter.load_zone && filter.load_zone.includes('null-')) {
        filter.load_zone = 'null';
    }

    if (filter.load_zone && filter.load_zone.includes('undefined-')) {
        filter.load_zone = undefined;
    }

    if (filter.market && filter.market.includes('null-')) {
        filter.market = 'null';
    }

    if (filter.market && filter.market.includes('undefined-')) {
        filter.market = undefined;
    }

    return filter;
}

export const exportTrackingAlerts = async ({
    companyId,
    start,
    end,
    is_opened,
    is_confirmed,
    user_ids,
    event_statuses,
    sorter,
}: IFetchAlertTrackQuery) => {
    if (sorter && (!sorter.field || !sorter.order)) {
        sorter = {};
    }

    const response = await API.fetch(
        `/alert/tracking?${new URLSearchParams({
            format: 'csv',
            ...(companyId && { companyId: companyId.toString() }),
            ...(start && { start: start.toISOString() }),
            ...(end && { end: end.toISOString() }),
            ...(!isNil(is_opened) && { is_opened }),
            ...(!isNil(is_confirmed) && { is_confirmed }),
            ...(user_ids && { user_ids }),
            ...(event_statuses && { event_statuses }),
            ...(sorter?.field && { sortField: sorter.field, sortOrder: sorter.order }),
        })}`,
        {
            headers: {
                'Content-Type': 'text/csv',
            },
        }
    );

    if (response.ok) {
        const respBody: string = await response.text();

        const period =
            start && end
                ? `${format(new Date(start), 'MM-dd-yyyy')}_${format(new Date(end), 'MM-dd-yyyy')}`
                : `${format(new Date(), DATE_FORMAT_CSV)}`;
        const filename = `events-tracking-alerts_${period}.csv`;
        exportCSVData(respBody, filename);
    } else {
        const respBody: any = await response.json();
        throw new Error(respBody?.error?.message || `Cannot export data!`);
    }
};

export const fetchEventsMetrics = async (
    { companyId, start, end }: { companyId: any; start: Date; end: Date },
    signal?: AbortSignal | null
): Promise<IEventMetrics> => {
    const path = `/events/metrics?${new URLSearchParams({
        start: start.toISOString(),
        end: end.toISOString(),
        ...(companyId && { companyId }),
    })}`;
    const response = await API.fetch(path, {
        ...(signal && { signal }),
        headers: {
            'Content-Type': 'application/json',
        },
    });

    const respBody: any = await response.json();
    if (response.ok) {
        return respBody;
    }

    throw new Error(respBody?.error?.message || `Cannot fetch event metrics!`);
};

/**
 * Event is completed if it and its post event ar finished
 * @param event
 * @returns
 */
export const isCompleted = (event: IEvent) => {
    if (!event.event_id) return false;
    let postEventDate = add(new Date(event.event_datetime_start), { minutes: event.event_duration });
    postEventDate = add(postEventDate, { minutes: event.event_post_duration });
    return isAfter(new Date(), postEventDate);
};

// return date without depends on timeZone
export const toUniversalTime = (date: Date) => {
    const selectedDate = new Date(date);
    selectedDate.setSeconds(0);
    selectedDate.setMilliseconds(0);

    const timeOffset = selectedDate.getTimezoneOffset();

    return subMinutes(selectedDate, timeOffset).toISOString();
};

export async function fetchEventsCountBySites(
    siteIds: number[],
    startDate: Date,
    endDate: Date
): Promise<{ data: IEventsCountBySiteAndYears }> {
    const path = `/events/count?${new URLSearchParams({
        siteIds: siteIds.join(','),
        start: startDate.toISOString(),
        end: endDate.toISOString(),
    })}`;

    const response = await API.fetch(path, {
        method: 'get',
        headers: {
            'Content-Type': 'application/json',
        },
    });

    if (response.ok) {
        return await response.json();
    }

    const respBody: any = await response.json();
    throw new Error(respBody?.error?.message || `Cannot fetch site!`);
}
