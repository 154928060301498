import Card from 'antd/lib/card';
import Col from 'antd/lib/col';
import Row from 'antd/lib/row';
import Skeleton from 'antd/lib/skeleton';
import add from 'date-fns/add';
import endOfDay from 'date-fns/endOfDay';
import startOfDay from 'date-fns/startOfDay';
import { useContext, useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import { AbilityContext } from '../../components/ability/can';
import { useCompanies } from '../../components/selectors/GlobalCompanySelector/CompanyContext';
import { useDocumentTitle } from '../../components/useDocumentTitle';
import { ISite, ISiteLocation } from '../../domain/site/interface';
import { usePageLocation } from '../usePageState';
import { ImpactInformation } from './components/impact/impactInformation';
import { MemoizedSitesMap } from './components/sitesMap/sitesMap';
import { WeatherForecast } from './components/weatherForecast/weatherForecast';
import { EventsDateWidget } from './EventsDateWidget';
import { useFetchMapLocations } from './useFetchMapLocations';
import './dashboard.css';

export const DATE = {
    today: {
        start: startOfDay(new Date()),
        end: endOfDay(new Date()),
    },
    tomorrow: {
        start: startOfDay(add(new Date(), { days: 1 })),
        end: endOfDay(add(new Date(), { days: 1 })),
    },
};

const getInitialCoordinates = (locations: ISiteLocation[]) => {
    const CALIFORNIA_COORDINATES = {
        lat: 36.7783,
        lng: -119.4179,
    };

    if (!locations || locations?.length === 0) {
        return CALIFORNIA_COORDINATES;
    }

    return { lat: locations[0]?.site_lat, lng: locations[0]?.site_long };
};

export function Dashboard() {
    const urlLocation = useLocation();
    const { setPageQuery, queryToState } = usePageLocation();
    const pageState: any = queryToState(urlLocation.search);

    const isActiveEventsMap = useRef(new Map<string, boolean>());

    const { company } = useCompanies()!;
    const ability = useContext(AbilityContext);

    const { locations, isLoading } = useFetchMapLocations(company);

    useDocumentTitle('Dashboard');

    const onSiteChange = (site: ISite) => {
        if (!site) return;

        const coordinates = { lat: site?.site_lat, lng: site?.site_long };
        setPageQuery({ ...pageState, location: coordinates });
    };

    const onMapLocationChange = (mapCenter: { lat: number; lng: number }) => {
        setPageQuery({ ...pageState, location: mapCenter });
    };

    const onActiveEventsHandle = (field: string) => (value: boolean) => isActiveEventsMap.current.set(field, value);

    useEffect(() => {
        isActiveEventsMap.current.clear();
    }, [company]);

    useEffect(() => {
        if (!pageState.location && !isLoading) {
            const coordinates = getInitialCoordinates(locations);
            setPageQuery({ ...pageState, location: coordinates });
        }
    }, [locations]);

    return (
        <Row key="" className="dashboard-page" gutter={[16, 16]} data-cy="dashboard-page">
            <Col xs={24} md={12} key="weather-forecast">
                <Card title="Energy Program Weekly Forecast">
                    {isLoading ? (
                        <Skeleton loading paragraph={{ rows: 5 }} active />
                    ) : (
                        <WeatherForecast location={pageState.location} isActiveEvent={isActiveEventsMap.current} />
                    )}
                    {company && (
                        <Row gutter={[14, 16]}>
                            <Col xs={24} md={12}>
                                <EventsDateWidget
                                    title="Today's Events"
                                    date={DATE.today}
                                    company={company}
                                    onActiveEventsHandle={onActiveEventsHandle('today')}
                                />
                            </Col>
                            <Col xs={24} md={12}>
                                <EventsDateWidget
                                    title="Tomorrow's Events"
                                    date={DATE.tomorrow}
                                    company={company}
                                    onActiveEventsHandle={onActiveEventsHandle('tomorrow')}
                                />
                            </Col>
                        </Row>
                    )}
                </Card>
                {ability.can('read', 'ImpactReporting') && <ImpactInformation company={company} />}
            </Col>

            <Col xs={24} md={12} key="sites-map">
                <Card title="Locations" loading={isLoading}>
                    <MemoizedSitesMap
                        siteLocations={locations}
                        onMarkerClick={onSiteChange}
                        onMapLocationChange={onMapLocationChange}
                    />
                </Card>
            </Col>
        </Row>
    );
}
