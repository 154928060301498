import React, { useEffect, useMemo, useState } from 'react';
import sortBy from 'lodash/sortBy';
import Select from 'antd/lib/select';
import Tooltip from 'antd/lib/tooltip';
import { ISite } from '../../../domain/site/interface';
import { fetchSites } from '../../../domain/site';
import { usePrevious } from '../../usePrevious';
import { useCompanies } from '../GlobalCompanySelector/CompanyContext';

interface ISiteLabelsSelectorProps {
    siteLabels: string[];
    setSiteLabels: Function;
    companyId: number;
    allowClear?: boolean;
}

interface ISelectOption {
    value: string;
    label: string | React.ReactNode;
    sites: ISite[];
}

export const SiteLabelsSelector: React.FC<ISiteLabelsSelectorProps> = ({
    siteLabels,
    setSiteLabels,
    allowClear = true,
    companyId,
}) => {
    const [options, setOptions] = useState<ISelectOption[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const { companies } = useCompanies()!;
    const company = useMemo(() => companies.find((company) => company.company_id === companyId)!, [companyId]);
    const prevCompanyId = usePrevious(companyId);

    const handleChange = (value: string[]) => {
        setSiteLabels(value);
    };

    useEffect(() => {
        if (!companyId) return;

        (async () => {
            setIsLoading(true);

            const { data: sites } = await fetchSites({
                pagination: { pageSize: 10000 },
                company_id: companyId,
                include: 'company',
                hasLabel: true
            });

            const sitesGroup = new Map();
            const existedSiteLabels = new Set(sites.map(({ site_label }) => site_label).flat(1));

            for (const siteLabel of existedSiteLabels) {
                const sitesMatchedLabel = sites.filter((site) => site.site_label.includes(siteLabel));

                if (sitesMatchedLabel.length === 0) continue;

                sitesGroup.set(siteLabel, sitesMatchedLabel);
            }

            const siteGroupNames: string[] = sortBy([...sitesGroup.keys()]);

            const newOptions = siteGroupNames.map((groupName) => transformSiteGroupToOption(
                groupName,
                sitesGroup.get(groupName),
                company?.company_name!,
            ));

            setOptions(newOptions);
            setIsLoading(false);
        })();

    }, [companyId]);

    useEffect(() => {
        if (!companyId || !prevCompanyId) return;

        if (prevCompanyId !== companyId) {
            setSiteLabels([]);
        }
    }, [prevCompanyId, companyId]);

    return (
        <Select
            showSearch
            maxTagCount={2}
            allowClear={allowClear}
            loading={isLoading}
            options={options}
            mode='multiple'
            size='large'
            placeholder='Select target label'
            optionLabelProp='value'
            style={{ width: '100%' }}
            value={siteLabels}
            onChange={handleChange}
        />
    );
};

function transformSiteGroupToOption(groupName: string, sites: ISite[], companyName: string) {
    const sitesLength = sites.length;
    const siteNames = sortBy(sites.map(({ site_name }) => site_name));

    return {
        value: groupName,
        label: (
            <>
                <Tooltip title={companyName + ' / ' + groupName}>
                    <b>{companyName + ' / ' + groupName}</b>
                </Tooltip>
                <br />
                {sitesLength} site{sitesLength > 1 ? 's' : ''}
                {displaySiteNames(siteNames)}
                <small>{sitesLength > 5 ? `And ${sitesLength - 5} more...` : ''}</small>
            </>
        ),
        sites,
    };
}

function displaySiteNames(siteNames: string[]) {
    return siteNames.slice(0, 5).map((siteName, index) => (
        <span key={index} style={{ display: 'block' }}>{index + 1}. {siteName}</span>
    ));
}