import differenceInMinutes from 'date-fns/differenceInMinutes';
import { useContext, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { ColumnsType } from 'antd/lib/table/interface';
import DownloadOutlined from '@ant-design/icons/DownloadOutlined';
import PlusCircleOutlined from '@ant-design/icons/PlusCircleOutlined';
import ExclamationCircleOutlined from '@ant-design/icons/ExclamationCircleOutlined';
import MailOutlined from '@ant-design/icons/MailOutlined';
import Button from 'antd/lib/button';
import Table from 'antd/lib/table';
import Tag from 'antd/lib/tag';
import Typography from 'antd/lib/typography';
import App from 'antd/lib/app';
import HistoryOutlined from '@ant-design/icons/lib/icons/HistoryOutlined';

import { MemoizedDatetimePopover } from '../../components/datetimePopover/datetimePopover';
import { WithEmptyDataTable } from '../../components/table/withEmptyDataTable';
import { getColumnFilteredValue, getColumnSortOrder } from '../../components/table/columnFormatHelpers';
import { ActionList } from '../../components/table/actionList/actionList';
import { useCompanies } from '../../components/selectors/GlobalCompanySelector/CompanyContext';
import { AbilityContext } from '../../components/ability/can';
import { IUser, UserType } from '../../domain/user/interface';
import { exportUsers } from '../../domain/user';
import { useAuth } from '../../domain/auth/useAuth';
import { usePageLocation } from '../usePageState';
import { toUsefulParams } from '../toUsefulParams';
import { userTypeColor, userTypeFilter } from './interface';
import { DEFAULT_PAGINATION, PAGE_ACTION } from '../../domain/commonConst';
import { SearchInput } from '../../components/searchInput/searchInput';
import { FORM_MODE, useFormMode } from '../../components/useFormMode';
import { PageHeader } from '../../components/pageHeader/pageHeader';
import { AuditTrailModal } from '../../components/modals/AuditTrailModal/AuditTrailModal';
import { AuditEntityType } from '../../domain/audit-trail/interface';
import { ResendScheduledAndActiveEventsModal } from './components/resendScheduledAndActiveEventsModal/resendScheduledAndActiveEventsModal';
import { UserFormModal } from './components/userFormModal/UserFormModal';
import { useUserDelete, useUserListQuery } from '../../domain/user/queries';
import { IPaginationMeta } from '../../domain/IPagination';
import { useDocumentTitle } from '../../components/useDocumentTitle';
import { getTablePaginationParams } from 'src/components/table/getTablePaginationParams';
import { compareWithLocale } from 'src/domain/compareWithLocale';
import './Users.css';

export function Users() {
    useDocumentTitle('Users');

    const location = useLocation();
    const { notification, modal } = App.useApp();
    const { setPageQuery, queryToState } = usePageLocation();
    const pageState = queryToState(location.search);

    const [user, setUser] = useState<IUser | Partial<IUser> | null>(null);
    const [usersAction, setUsersAction] = useState<PAGE_ACTION | ''>();
    const [exportLoading, setExportLoading] = useState<boolean>(false);
    const [resendModalProps, setResendModalProps] = useState<{ user: IUser; companyId: number } | null>(null);

    const { companies, company } = useCompanies()!;
    const ability = useContext(AbilityContext);
    const { onSetFormMode, formMode } = useFormMode();
    const auth = useAuth()!;
    const isCustomerType = useMemo(() => auth.user?.user_type === UserType.CUSTOMER, [auth]);

    const { mutate: deleteUser } = useUserDelete();

    const listQuery = {
        pagination: pageState?.pagination ?? DEFAULT_PAGINATION,
        ...(pageState?.sorter && { sorter: pageState?.sorter }),
        ...(pageState?.filter && { filter: pageState?.filter }),
        ...(pageState?.search && { search: pageState?.search }),
        ...(pageState?.companyId && { company_id: pageState?.companyId }),
        include: 'company',
    };
    const { data, isLoading, isError, error, refetch: refetchUserList } = useUserListQuery(listQuery, {
        keepPreviousData: true,
    });

    const users = data?.data || [];
    const meta = data?.meta ?? ({} as IPaginationMeta);

    if (isError) {
        notification.error({ key: 'users-fetch-error', message: error?.message || 'Cannot fetch users!' });
    }

    function showNewUserModal() {
        onSetFormMode(FORM_MODE.NEW);
        setUser({
            user_id: undefined,
            company_id: isCustomerType ? auth.user?.company_id : undefined,
            user_fname: undefined,
            user_lname: undefined,
            user_email: '',
            user_phone: undefined,
            user_type: undefined,
            user_password: undefined,
        });
    }

    function showEditUserModal(record: IUser) {
        return () => {
            onSetFormMode(FORM_MODE.EDIT);
            setUser({ ...record });
        };
    }

    function deleteUserWithConfirmation(record: IUser) {
        return () => {
            modal.confirm({
                icon: <ExclamationCircleOutlined />,
                content: `Deleting user ${record.user_email || ''}. Are you sure?`,
                onOk() {
                    deleteUserAction(record);
                },
                onCancel() {
                    console.log('Cancel');
                },
            });
        };
    }

    async function deleteUserAction(user: IUser) {
        try {
            deleteUser(user);
            notification.success({ key: 'user-delete-success', message: 'User deleted successfully' });

            const params = toUsefulParams({ ...pageState, companyId: company });
            const updatedTotal = meta.total - 1;
            const updatedPagesCount = Math.ceil(updatedTotal / meta.limit);

            if (params?.pagination?.current > updatedPagesCount) {
                params.pagination.current = updatedPagesCount;
            }

            setPageQuery({ ...pageState, ...params });
        } catch (error: any) {
            notification.error({ key: 'user-delete-error', message: error.message || 'Cannot delete user' });
        }
    }

    async function exportUsersAction() {
        try {
            setExportLoading(true);
            await exportUsers(toUsefulParams({ ...pageState }));
        } catch (err: any) {
            notification.error({ key: 'users-export-error', message: err.message || 'Cannot export users!' });
        }
        setExportLoading(false);
    }

    async function onTableChange(pagination: any, filter: any, sorter: any) {
        const preparedParams = toUsefulParams({ pagination, filter, sorter });
        setPageQuery({ ...pageState, ...preparedParams });
    }

    async function onUserFormModalClose(user: IUser) {
        setUser(null);
        onSetFormMode('');
    }

    function showResendEventModal(user: IUser) {
        return async () => {
            setResendModalProps({ user, companyId: user.company_id });
        };
    }

    function onResendEventModalClose() {
        setResendModalProps(null);
    }

    const handleSearch = async (value: string) => {
        setPageQuery({ ...pageState, search: value });

        // if search value is the same as in the state, then we need to refetch data and set pagination to 1 page
        if (value === (pageState.search || '')) {
            listQuery.pagination.current = DEFAULT_PAGINATION.current;

            await refetchUserList(listQuery);
        }
    };

    function showAuditTrailModal(record: IUser) {
        return () => {
            setUsersAction(PAGE_ACTION.AUDIT_TRAIL);
            setUser(record);
        };
    }

    const actions = (userRecord: IUser) => {
        const isSameUser = userRecord.user_id === auth.user?.user_id;
        const isRoleBelow = userRecord.user_type !== auth.user?.user_type || (auth.user?.user_super_admin && !userRecord.user_super_admin);

        const actionList = [
            ...(!isSameUser && isRoleBelow
                ? [
                    {
                        title: 'Edit',
                        onClick: (record: IUser) => showEditUserModal(record),
                    },
                    {
                        title: 'Delete',
                        onClick: (record: IUser) => deleteUserWithConfirmation(record),
                    },
                ]
                : []),
            ...(auth.user?.isAdminRoleType()
                ? [
                    {
                        title: 'Audit Trail',
                        onClick: (record: IUser) => showAuditTrailModal(record),
                        icon: <HistoryOutlined />,
                    },
                ]
                : []),
            ...(auth.user?.isAdmin()
                ? [
                    {
                        title: () => (
                            <span>
                                Resend Scheduled and <br />
                                Active Events
                            </span>
                        ),
                        icon: <MailOutlined />,
                        onClick: showResendEventModal,
                    },
                ]
                : []),
        ];

        return actionList;
    };

    const columns: ColumnsType<IUser> = [
        {
            title: 'Company',
            dataIndex: ['company_id'],
            render: (text, record) => {
                return <div data-cy="company-name-cell">{record.company.company_name}</div>;
            },
        },
        {
            title: 'Name',
            dataIndex: 'user_fname',
            sorter: (a, b) => compareWithLocale(a.user_fname, b.user_fname),
            ...getColumnSortOrder('user_fname', pageState?.sorter),
            render: (text, record) => <div data-cy="user-name-cell">{`${text} ${record.user_lname}`}</div>,
        },
        {
            title: 'Email Address',
            dataIndex: 'user_email',
            defaultSortOrder: 'descend',
            sorter: (a, b) => compareWithLocale(a.user_email, b.user_email),
            ...getColumnSortOrder('user_email', pageState?.sorter),
            render: email => <div data-cy="user-email-cell">{email}</div>,
        },
        {
            title: 'Phone',
            dataIndex: 'user_phone',
            defaultSortOrder: 'descend',
            sorter: (a, b) => compareWithLocale(a.user_phone, b.user_phone),
            ...getColumnSortOrder('user_phone', pageState?.sorter),
        },
        {
            title: 'Type',
            dataIndex: 'user_type',
            filterMultiple: false,
            sortDirections: ['descend', 'ascend'],
            ...(auth.user?.user_type !== UserType.OPERATOR && {
                filters: userTypeFilter(auth.user!.user_type),
                onFilter: (value, record) => record.user_type === value,
                ...getColumnFilteredValue('user_type', pageState?.filter),
                sorter: (a, b) => compareWithLocale(a.user_type, b.user_type),
                ...getColumnSortOrder('user_type', pageState?.sorter),
            }),
            render: (type: UserType) => {
                return (
                    <Tag color={userTypeColor[type]} key={type}>
                        {type.toUpperCase()}
                    </Tag>
                );
            },
        },
        ...(auth.user?.isAdminRoleType()
            ? [
                {
                    title: 'Site Labels',
                    dataIndex: 'site_label',
                    render: (labels: string[]) =>
                        labels.map(label => (
                            <Typography className="ellipsis">
                                <Tag key={label} color="gold" style={{ display: 'initial' }}>
                                    {label}
                                </Tag>
                            </Typography>
                        )),
                },
            ]
            : []),
        {
            title: 'Last Login',
            dataIndex: 'user_date_last_login',
            defaultSortOrder: 'descend',
            width: 200,
            sorter: (a, b) => compareWithLocale(a.user_date_last_login, b.user_date_last_login),
            ...getColumnSortOrder('user_date_last_login', pageState?.sorter),
            render: (text, record) => {
                const datesDifference = differenceInMinutes(new Date(text), new Date(record.user_date_created));
                return datesDifference <= 1
                    ? null
                    : <MemoizedDatetimePopover timestamp={new Date(text).valueOf()} dateFormat='dd/MMM/yyyy hh:mm a' />;
            },
        },
        ...(ability.can('update', 'User') || ability.can('delete', 'User')
            ? [
                {
                    width: 100,
                    sorter: false,
                    render: (text: string, record: IUser) => <ActionList actions={actions(record)} item={record} />,
                },
            ]
            : []),
    ];

    return (
        <div className='users-page'>
            <PageHeader
                pageTitle={`Users (${meta.total || 0})`}
                actions={[
                    <SearchInput key="search-user-page" onSearch={handleSearch} defaultValue={pageState?.search} />,
                    <Button
                        size="large"
                        key="download-csv"
                        onClick={exportUsersAction}
                        icon={<DownloadOutlined />}
                        loading={exportLoading}
                        data-cy="users-download"
                    >
                        Download CSV
                    </Button>,
                    ability.can('create', 'User') && (
                        <Button
                            size="large"
                            key="new-user"
                            type="primary"
                            onClick={showNewUserModal}
                            icon={<PlusCircleOutlined />}
                            data-cy="create-user"
                        >
                            New User
                        </Button>
                    ),
                ]}
            />
            <WithEmptyDataTable condition={isLoading}>
                <Table
                    className="users-table"
                    size="small"
                    rowKey="user_id"
                    sticky
                    columns={columns}
                    dataSource={users}
                    pagination={getTablePaginationParams(meta)}
                    loading={isLoading}
                    onChange={onTableChange}
                />
            </WithEmptyDataTable>
            {user && (formMode === FORM_MODE.EDIT || formMode === FORM_MODE.NEW) && (
                <UserFormModal user={user} companies={companies} onClose={onUserFormModalClose} />
            )}
            {user && usersAction === PAGE_ACTION.AUDIT_TRAIL && (
                <AuditTrailModal onClose={() => setUsersAction('')} entityId={user.user_id!} entityType={AuditEntityType.USER} />
            )}
            {resendModalProps && (
                <ResendScheduledAndActiveEventsModal
                    user={resendModalProps.user}
                    companyId={resendModalProps.companyId}
                    onClose={onResendEventModalClose}
                />
            )}
        </div>
    );
}
