import { fill, isString, map, some, toLower } from 'lodash';
import { ICompany } from '../../../domain/company/interface';
import { IProgram } from '../../../domain/program/interface';
import { ISiteStoreNumberCheckResponse } from '../../../domain/site/interface';
import { SiteImportTableIndexes as Idx } from './siteImportTableIndexes';
import { removeNonUTF8ASCII } from '../../../domain/stringCleaner';

// 20 columns with data + 1 with import error
const COLUMNS_COUNT = 21;

export class SitesParser {
    private companies: ICompany[] = [];
    private providers: ICompany[] = [];
    private utilityCustomers: ICompany[] = [];
    private programs: IProgram[] = [];

    parse(
        records: any[][],
        companies: ICompany[],
        providers: ICompany[],
        utilityCustomers: ICompany[],
        programs: IProgram[],
        duplicatedSitesByStoreNumber: ISiteStoreNumberCheckResponse[]
    ): any[] {
        this.companies = companies;
        this.providers = providers;
        this.utilityCustomers = utilityCustomers;
        this.programs = programs;

        /** VALIDATION */
        // Empty errors array
        const errors = map(records, record => fill(Array(record.length), null));

        records.forEach((record, i) => {
            const [item, rowErrors] = this.validateRecord(record);
            // set cleaned value to returned record
            record = item[i];
            errors[i] = rowErrors;
        });

        const warnings: any[][] = this.getWarningsForRecords(records, duplicatedSitesByStoreNumber);

        return [records, errors, warnings];
    }

    private validateRecord(record: any[]) {
        const COLUMNS_COUNT = 20;
        let rowErrors: any[] = new Array(COLUMNS_COUNT);

        // clean string values
        record = this.cleanStringItems(record);

        rowErrors[Idx.COMPANY_NAME] = this.validateCompany(record[Idx.COMPANY_NAME], this.companies);
        rowErrors[Idx.CONTROL_PROVIDER_NAME] = this.validateControlProvider(
            record[Idx.CONTROL_PROVIDER_NAME],
            this.providers
        );
        rowErrors[Idx.UTILITY_CUSTOMER_NAME] = this.validateUtilityCustomer(
            record[Idx.UTILITY_CUSTOMER_NAME],
            this.utilityCustomers
        );
        rowErrors[Idx.PROGRAMS] = this.validatePrograms(record[Idx.PROGRAMS], this.programs);
        rowErrors[Idx.SITE_NAME] = this.validateSiteName(record[Idx.SITE_NAME]);
        rowErrors[Idx.SITE_STORE_NUMBER] = this.checkForIncorrectEncodedValues(record[Idx.SITE_STORE_NUMBER]);
        rowErrors[Idx.SITE_ADDRESS] = this.validateSiteAddress(record[Idx.SITE_ADDRESS]);
        rowErrors[Idx.SITE_CITY] = this.validateSiteCity(record[Idx.SITE_CITY]);
        rowErrors[Idx.SITE_STATE] = this.validateSiteState(record[Idx.SITE_STATE]);
        rowErrors[Idx.SITE_ZIP] = this.validateSiteZip(record[Idx.SITE_ZIP]);
        rowErrors[Idx.SITE_MARKET] = this.validateMarket(record[Idx.SITE_MARKET]);
        rowErrors[Idx.SITE_LOAD_ZONE] = this.validateLoadZone(record[Idx.SITE_LOAD_ZONE], record[Idx.SITE_MARKET]);
        rowErrors[Idx.SITE_ESTIMATED_KW] = this.checkForIncorrectEncodedValues(record[Idx.SITE_ESTIMATED_KW]);
        rowErrors[Idx.SITE_SERVICE_ACCOUNT_NUMBER] = this.validateEnrollmentIds(
            record[Idx.SITE_SERVICE_ACCOUNT_NUMBER]
        );
        rowErrors[Idx.SITE_UTILITY] = this.checkForIncorrectEncodedValues(record[Idx.SITE_UTILITY]);
        rowErrors[Idx.SITE_SLAP] = this.checkForIncorrectEncodedValues(record[Idx.SITE_SLAP]);
        rowErrors[Idx.SITE_NETWORK] = this.checkForIncorrectEncodedValues(record[Idx.SITE_NETWORK]);
        rowErrors[Idx.EVENT_OFFSET] = this.checkForIncorrectEncodedValues(record[Idx.EVENT_OFFSET]);
        rowErrors[Idx.EVENT_MAX_DURATION] = this.checkForIncorrectEncodedValues(record[Idx.EVENT_MAX_DURATION]);
        rowErrors[Idx.CREATE_VEN] = this.checkForIncorrectEncodedValues(record[Idx.CREATE_VEN]);
        rowErrors[Idx.PARTNER] = this.checkForIncorrectEncodedValues(record[Idx.PARTNER]);

        return [record, rowErrors];
    }

    private cleanStringItems(record: any[]) {
        const stringIndexes = [
            Idx.SITE_NAME,
            Idx.SITE_STORE_NUMBER,
            Idx.SITE_ADDRESS,
            Idx.SITE_CITY,
            Idx.SITE_STATE,
            Idx.SITE_ZIP,
            Idx.SITE_SLAP,
            Idx.SITE_NETWORK,
            Idx.SITE_UTILITY,
            Idx.PARTNER,
            Idx.SITE_LOAD_ZONE,
            Idx.SITE_MARKET,
            Idx.SITE_SERVICE_ACCOUNT_NUMBER,
        ];

        for (const key of stringIndexes) {
            record[key] = removeNonUTF8ASCII(record[key]);
        }

        return record;
    }

    private validateEnrollmentIds(enrollmentIdString: string | null): string | null {
        let incorrectEncodedValues = this.checkForIncorrectEncodedValues(enrollmentIdString || '');
        if (incorrectEncodedValues) return incorrectEncodedValues;

        if (!enrollmentIdString) return null;

        const enrollmentIds = enrollmentIdString
            .split(',')
            .map(id => id.trim())
            .filter(Boolean);
        if (enrollmentIds.length > 100) {
            return 'Maximum 100 enrollment IDs allowed';
        }

        return null;
    }

    private validateCompany(name: string, companies: ICompany[]): string | null {
        if (!name) return `company name is required`;

        const encodingError = this.checkForIncorrectEncodedValues(name);
        if (encodingError) return encodingError;

        const unknown = !some(companies, company => toLower(company.company_name) === toLower(name));
        if (unknown) return `unknown company`;

        return null;
    }

    private validateControlProvider(name: string, providers: ICompany[]): string | null {
        /** control provider is not required */
        if (!name) return null;

        const encodingError = this.checkForIncorrectEncodedValues(name);
        if (encodingError) return encodingError;

        const unknown = !some(providers, provider => toLower(provider.company_name) === toLower(name));
        if (unknown) return `unknown control provider`;

        return null;
    }

    private validateUtilityCustomer(name: string, utilityCustomers: ICompany[]): string | null {
        /** utility customer is not required */
        if (!name) return null;

        const encodingError = this.checkForIncorrectEncodedValues(name);
        if (encodingError) return encodingError;

        const unknown = !some(utilityCustomers, item => toLower(item.company_name) === toLower(name));
        if (unknown) return `unknown utility customer`;

        return null;
    }

    private validatePrograms(programsStr: string, programs: IProgram[]): string | null {
        if (!programsStr) return null;

        const encodingError = this.checkForIncorrectEncodedValues(programsStr);
        if (encodingError) return encodingError;

        // Split and trim program names, remove redundant spaces, filter out empty strings
        const programNames = programsStr
            .split(',')
            .map(p => p.trim())
            .filter(Boolean);

        // Collect errors for unknown programs
        const unknownErrors = programNames.reduce((errors, name) => {
            const isKnownProgram = programs.some(program => toLower(program.name) === toLower(name));

            if (!isKnownProgram) {
                errors.push(`unknown program - "${name}"`);
            }

            return errors;
        }, [] as string[]);

        return unknownErrors.length > 0 ? unknownErrors.join('; ') : null;
    }

    private validateMarket(value: string) {
        return this.checkForIncorrectEncodedValues(value);
    }

    private validateLoadZone(loadZone?: string, market?: string) {
        if (!market && loadZone) return `cannot have load zone without market`;

        const encodingError = this.checkForIncorrectEncodedValues(loadZone);
        if (encodingError) return encodingError;
    }

    private validateSiteName(value: string) {
        if (!value) return `site name is required`;

        const encodingError = this.checkForIncorrectEncodedValues(value);
        if (encodingError) return encodingError;
    }

    private validateSiteAddress(value: string) {
        if (!value) return `address is required`;

        const encodingError = this.checkForIncorrectEncodedValues(value);
        if (encodingError) return encodingError;
    }

    private validateSiteCity(value: string) {
        if (!value) return `city is required`;

        const encodingError = this.checkForIncorrectEncodedValues(value);
        if (encodingError) return encodingError;
    }

    private validateSiteState(value: string) {
        if (!value) return `state is required`;

        const encodingError = this.checkForIncorrectEncodedValues(value);
        if (encodingError) return encodingError;
    }

    private validateSiteZip(value: string | number) {
        if (!value) return `zip is required`;

        const encodingError = this.checkForIncorrectEncodedValues(value);
        if (encodingError) return encodingError;
    }

    private checkForIncorrectEncodedValues(value?: string | number): string | null {
        const incorrectEncodeSymbol = '�';

        // Check for string as zip code can be parsed as number
        if (isString(value) && value.includes(incorrectEncodeSymbol)) {
            return 'Your file contains incorrect encoded data, please make sure to save file with UTF-8 encoding format';
        }

        return null;
    }

    private getWarningsForRecords(records: any[][], duplicatedSites: ISiteStoreNumberCheckResponse[]) {
        const warnings: any[][] = [];

        records.forEach((record, index) => {
            const recordStoreNumber = record[Idx.SITE_STORE_NUMBER];
            if (!recordStoreNumber) return;

            const recordCompanyName = record[Idx.COMPANY_NAME];
            const companyId = this.companies.find(company => company.company_name === recordCompanyName)?.company_id;

            warnings[index] = new Array(COLUMNS_COUNT);

            const isDuplicateOfExistingSite = duplicatedSites?.find(
                item => item.companyId === companyId && item.duplicatedStoreNumbers.includes(recordStoreNumber)
            );

            const duplicatesFromImport = records.filter(
                item =>
                    item[Idx.SITE_STORE_NUMBER] === recordStoreNumber && item[Idx.COMPANY_NAME] === recordCompanyName
            );

            if (isDuplicateOfExistingSite && recordCompanyName) {
                warnings[index][Idx.SITE_STORE_NUMBER] = 'Site with this store number already exists';
                return;
            }

            if (duplicatesFromImport.length > 1 && recordCompanyName) {
                warnings[index][Idx.SITE_STORE_NUMBER] =
                    'Site with this store number duplicates another site from this import file.';
                return;
            }
        });

        return warnings;
    }
}
