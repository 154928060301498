import { UploadFile } from 'antd/lib';

export interface IFeedback {
    title: string;
    category?: CustomerFeedbackCategory;
    description: string;
    otherInfo?: string;
    files?: UploadFile[];
}

export enum CustomerFeedbackCategory {
    FEATURE_REQUEST = 'feature-request',
    BUG_REPORT = 'bug-report',
    REPORTING_DATA_ISSUE = 'reporting-data-issue',
    OTHER = 'other',
}
