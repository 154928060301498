import { supportedEnergyMarkets } from 'src/domain/market-prices/interface';
import { COMPANY_TYPE, ICompany, ICreateCompany, IUpdateCompany } from '../../domain/company/interface';

export interface ICompanyFormData {
    company_id?: number;
    company_name: string;
    type: COMPANY_TYPE;
    utility_names: string[];
    company_vertical?: string;
    api_credentials?: {
        copeland_key: string;
        copeland_key_error?: string;
    } | null;
    utility_iso?: string;
    account_ids?: string[];
}

export class CompanyFormData {
    static fromEntity(company: ICompany | Partial<ICompany>): ICompanyFormData {
        return {
            company_id: company.company_id,
            company_name: company.company_name!,
            type: company.type!,
            utility_names: company.utility_names || [],
            company_vertical: company.company_vertical,
            api_credentials: company.api_credentials,
            utility_iso: company.utility_id || company.iso,
            account_ids: company.accounts?.map(account => account.salesforce_id) || [],
        };
    }

    static toEntity(formData: ICompanyFormData): ICreateCompany | IUpdateCompany {
        const utilityISOfieldName = supportedEnergyMarkets.includes(formData.utility_iso!) ? 'iso' : 'utility_id';

        return {
            company_id: formData?.company_id,
            company_name: formData.company_name,
            type: formData.type,
            utility_names: formData.utility_names,
            company_vertical: formData.company_vertical,
            api_credentials: formData.api_credentials,
            [utilityISOfieldName]: formData.utility_iso,
            account_ids: formData.account_ids || [],
        };
    }
}
