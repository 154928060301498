import DownloadOutlined from '@ant-design/icons/DownloadOutlined';
import ExclamationCircleOutlined from '@ant-design/icons/ExclamationCircleOutlined';
import CopyOutlined from '@ant-design/icons/lib/icons/CopyOutlined';
import PlusCircleOutlined from '@ant-design/icons/PlusCircleOutlined';
import App from 'antd/lib/app';
import Button from 'antd/lib/button';
import Table from 'antd/lib/table';
import { ColumnsType } from 'antd/lib/table/interface';
import add from 'date-fns/add';
import groupBy from 'lodash/groupBy';
import { useContext, useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';
import { useLocation } from 'react-router-dom';
import { AbilityContext } from '../../components/ability/can';
import { MemoizedDatetimePopover } from '../../components/datetimePopover/datetimePopover';
import { PageHeader } from '../../components/pageHeader/pageHeader';
import { ActionList, IActionList } from '../../components/table/actionList/actionList';
import { WithEmptyDataTable } from '../../components/table/withEmptyDataTable';
import { useDocumentTitle } from '../../components/useDocumentTitle';
import { DEFAULT_PAGINATION } from '../../domain/commonConst';
import { IPaginationMeta } from '../../domain/IPagination';
import { exportSettlements } from '../../domain/settlement';
import { ISettlementFile } from '../../domain/settlement/interface';
import { useDeleteSettlementMutation, useSettlementsListQuery } from '../../domain/settlement/queries';
import { validatePagination } from '../../domain/validatePagination';
import { toUsefulParams } from '../toUsefulParams';
import { usePageLocation } from '../usePageState';
import { SettlementFileUploadModal } from './SettlementFileUploadModal';
import { getTablePaginationParams } from 'src/components/table/getTablePaginationParams';

interface IGroupedSettlement {
    settlement_file_datetime_created: string,
    expandedTitle: boolean,
    children: ISettlementFile[];
}

const DEFAULT_SORTER = {
    field: 'settlement_file_datetime_created',
    order: 'descend',
}

export function Settlements() {
    const { notification, modal } = App.useApp();
    const [, setCookie] = useCookies(['settlement_viewed']);
    const location = useLocation();
    const { setPageQuery, queryToState } = usePageLocation();
    const pageState: any = queryToState(location.search);

    const [isExportLoading, setIsExportLoading] = useState(false);
    const [groupedSettlements, setGroupedSettlements] = useState<IGroupedSettlement[]>([]);
    const [settlement, setSettlement] = useState<ISettlementFile | Partial<ISettlementFile>>();
    const [expandedKeys, setExpandedKeys] = useState<string[]>([]);

    const ability = useContext(AbilityContext);

    const deleteSettlementMutation = useDeleteSettlementMutation();

    const listQuery = {
        pagination: pageState?.pagination ?? DEFAULT_PAGINATION,
        sorter: pageState?.sorter ?? DEFAULT_SORTER,
        ...(pageState?.companyId && { company_id: pageState.companyId }),
        include: 'company',
    };

    const { data, isLoading, isError, error, refetch: refetchSettlements } = useSettlementsListQuery(listQuery, {
        keepPreviousData: true,
    });
    const settlements = data?.data;
    const meta = data?.meta ?? ({ total: 0 } as IPaginationMeta);

    if (isError) {
        notification.error({ key: 'settlements-fetch-error', message: error?.message || 'Cannot fetch settlements!' });
    }

    useDocumentTitle('Settlements');

    useEffect(() => {
        if (!settlements) return;

        const expires = add(new Date(), { days: 30 });
        setCookie('settlement_viewed', new Date().valueOf(), { path: '/', expires });

        const groupedData = groupByYear(settlements);
        groupedData.length && setExpandedKeys([groupedData[0].settlement_file_datetime_created]);

        setGroupedSettlements(groupedData);
    }, [setCookie, settlements]);

    async function onTableChange(pagination: any, filter: any, sorter: any) {
        const preparedParams = toUsefulParams({ pagination, sorter });
        setPageQuery({ ...pageState, ...preparedParams });
    }

    function showNewSettlementFileModal() {
        setSettlement({
            settlement_file_id: undefined,
            company_id: undefined,
            settlement_file_name: undefined,
            settlement_file_size: undefined,
            settlement_file_type: undefined,
            settlement_file_hash: undefined,
            settlement_file_datetime_created: undefined,
        });
    }

    async function onSettlementFileUploadModalClose(settlement?: ISettlementFile | null) {
        setSettlement(undefined);

        if (settlement) {
            await refetchSettlements(listQuery);
        }
    }

    function downloadSettlementFile(record: ISettlementFile) {
        return () => {
            window.open(record.downloadLink, '_self');
        };
    }

    function copySettlementLink(record: ISettlementFile) {
        return async () => {
            await navigator.clipboard.writeText(record.downloadLink || '');
            notification.success({ key: 'settlement-link-copy-success', message: 'Link copied to clipboard' });
        };
    }

    function deleteSettlementFile(record: ISettlementFile) {
        return () => {
            modal.confirm({
                icon: <ExclamationCircleOutlined />,
                content: `Deleting file ${record.settlement_file_name || ''}. Are you sure?`,
                onOk() {
                    deleteSettlementAction(record);
                },
            });
        };
    }

    async function deleteSettlementAction(settlement: ISettlementFile) {
        try {
            await deleteSettlementMutation.mutateAsync(settlement);
            notification.info({ key: 'settlement-delete-info', message: 'Settlement deleted' });

            const validPagination = validatePagination(pageState.pagination, meta.total - 1);

            setPageQuery({ ...pageState, pagination: validPagination });
            await refetchSettlements({ ...pageState, pagination: validPagination });
        } catch (err: any) {
            notification.error({ key: 'settlement-delete-error', message: err.message || 'Cannot delete settlement!' });
        }
    }

    async function exportSettlementsAction() {
        setIsExportLoading(true);

        try {
            await exportSettlements(listQuery);
        } catch (err: any) {
            notification.error({ key: 'settlements-export-error', message: err.message || 'Cannot export settlements!' });
        }

        setIsExportLoading(false);
    }

    const handleExpandedRow = (expanded: boolean, record: IGroupedSettlement) => {
        const newExpandedKeys = expanded
            ? [...expandedKeys, record.settlement_file_datetime_created]
            : expandedKeys.filter((key: string) => key !== record.settlement_file_datetime_created);
        setExpandedKeys(newExpandedKeys);
    };

    const columns: ColumnsType<IGroupedSettlement> = [
        {
            title: 'Date',
            width: 250,
            dataIndex: 'settlement_file_datetime_created',
            render: (text, record, index) => {
                return record?.expandedTitle ? text : <MemoizedDatetimePopover timestamp={new Date(text).valueOf()} dateFormat='dd/MMM/yyyy' />;
            },
        },
        {
            title: 'Company',
            dataIndex: 'company_name',
            // @ts-ignore
            render: (text, record: ISettlementFile) => record?.company?.company_name
        },
        {
            title: 'Name',
            dataIndex: 'settlement_file_name',
            render: (text, record: any, index) => !record?.expandedTitle &&
                <a
                    href={record.previewLink || record.downloadLink}
                    target={record.previewLink ? '_blank' : '_self'}
                    rel='noreferrer'
                >{text}</a>,
        },
        {
            key: 'action',
            sorter: false,
            width: 150,
            render: (text: string, record: any, index: number) =>
                !record?.expandedTitle && (
                    <ActionList actions={actions} item={record} />
                ),
        },
    ];

    const actions: IActionList['actions'] = [
        {
            title: 'Download',
            onClick: (record: ISettlementFile) => downloadSettlementFile(record),
            icon: <DownloadOutlined />,
        },
        {
            title: 'Copy public link to the file, link will be expired in 1 hour',
            onClick: (record: ISettlementFile) => copySettlementLink(record),
            icon: <CopyOutlined />,
        },
    ];

    if (ability.can('delete', 'Settlement')) {
        actions.push({
            title: 'Delete',
            onClick: (record: ISettlementFile) => deleteSettlementFile(record),
        });
    }

    return (
        <>
            <PageHeader
                pageTitle={`Settlements (${meta.total})`}
                actions={[
                    <Button
                        size='large'
                        key='download-csv'
                        onClick={exportSettlementsAction}
                        icon={<DownloadOutlined />}
                        loading={isExportLoading}
                        data-cy='csv-download'
                    >
                        Download CSV
                    </Button>,
                    ability.can('create', 'Settlement') && (
                        <Button
                            size='large'
                            key='new-settlement-file'
                            type='primary'
                            onClick={showNewSettlementFileModal}
                            icon={<PlusCircleOutlined />}
                        >
                            New File
                        </Button>
                    ),
                ]}
            />
            <WithEmptyDataTable condition={isLoading}>
                <Table
                    size='small'
                    rowKey='settlement_file_datetime_created'
                    sticky
                    columns={columns}
                    expandable={{
                        expandedRowKeys: expandedKeys,
                        onExpand: handleExpandedRow,
                    }}
                    dataSource={groupedSettlements}
                    pagination={getTablePaginationParams(meta)}
                    loading={isLoading}
                    onChange={onTableChange}
                />
            </WithEmptyDataTable>

            {settlement &&
                <SettlementFileUploadModal settlement={settlement} onClose={onSettlementFileUploadModalClose} />}
        </>
    );
}

function groupByYear(data: ISettlementFile[] = []): IGroupedSettlement[] {
    const getYear = (item: ISettlementFile) => new Date(item.settlement_file_datetime_created).getFullYear();
    const groupedData = groupBy(data, getYear);

    return Object.keys(groupedData)
        .map(item => ({
            settlement_file_datetime_created: item,
            expandedTitle: true,
            children: groupedData[item],
        }))
        .sort((a, b) => +b.settlement_file_datetime_created - +a.settlement_file_datetime_created);
}
