import { orderBy } from 'lodash';
import Modal from 'antd/lib/modal';
import Typography from 'antd/lib/typography';
import Timeline, { TimelineItemProps } from 'antd/lib/timeline';
import Skeleton from 'antd/lib/skeleton';
import Empty from 'antd/lib/empty';
import App from 'antd/lib/app';
import { useAuditTrailListQuery } from '../../../domain/audit-trail';
import { AuditEntityType, IAuditTrail } from '../../../domain/audit-trail/interface';
import { MemoizedDatetimePopover } from '../../datetimePopover/datetimePopover';

interface IAuditTrailModalProps {
    onClose: Function;
    entityId: number | string;
    entityType: AuditEntityType;
}

export const AuditTrailModal = ({ onClose, entityId, entityType }: IAuditTrailModalProps) => {
    const { notification } = App.useApp();

    const {
        data,
        isLoading,
        isError,
        error
    } = useAuditTrailListQuery({ entityId, entityType }, {
        keepPreviousData: true,
    });

    if (isError) {
        notification.error({ key: 'audit-trail-fetch-error', message: error.message || 'Cannot fetch Audit trail data!' });
    }

    const auditTrailData = data?.data?.length ? orderBy(data.data, ['datetime', 'id'], ['desc', 'desc']) : [];

    const handleClose = () => onClose();

    return (
        <Modal
            open
            destroyOnClose
            title='Audit Trail'
            onCancel={handleClose}
            styles={{
                body: { maxHeight: '600px', overflow: 'auto' },
            }}
            footer={null}
        >
            {isLoading ? <Skeleton loading active /> : renderContent(auditTrailData)}
        </Modal>
    );
};

const renderContent = (auditTrailData: IAuditTrail[]) => {
    if (!auditTrailData.length) {
        return <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />;
    }

    const items: TimelineItemProps[] = [];

    auditTrailData.forEach((auditItem, index) => {
        items.push({
            key: `audit-timeline-item-${index}`,
            label: '',
            children: (
                <>
                    <Typography style={{ width: 'fit-content' }}>
                        <MemoizedDatetimePopover timestamp={new Date(auditItem.datetime).valueOf()} dateFormat='dd/MMM/yyyy hh:mm a' />
                    </Typography>

                    <Typography.Text>
                        {auditItem.actor}, {auditItem.action}
                    </Typography.Text>
                    {
                        auditItem.data && (
                            <Typography.Paragraph code style={{ whiteSpace: 'break-spaces' }} ellipsis>
                                {JSON.stringify(auditItem.data, null, 2)}
                            </Typography.Paragraph>
                        )
                    }
                </>)
        })

    });

    return <Timeline items={items} />;
}