import QuestionCircleOutlined from '@ant-design/icons/QuestionCircleOutlined';
import App from 'antd/lib/app';
import Button from 'antd/lib/button';
import Card from 'antd/lib/card';
import Form from 'antd/lib/form';
import { Col, Row } from 'antd/lib/grid';
import Input from 'antd/lib/input';
import Modal from 'antd/lib/modal';
import Switch from 'antd/lib/switch';
import Tooltip from 'antd/lib/tooltip';
import Typography from 'antd/lib/typography';
import React, { useMemo, useState } from 'react';
import { CarbonIntensityInput } from '../../../components/CarbonIntensityInput/CarbonIntensityInput';
import { EventDurationInput } from '../../../components/EventDurationInput/EventDurationInput';
import {
    CleanResponseMarketSelector,
} from '../../../components/selectors/CleanResponseMarketSelector/CleanResponseMarketSelector';
import {
    CleanResponseTargetSitesSelector,
} from '../../../components/selectors/CleanResponseTargetSitesSelector/CleanResponseTargetSitesSelector';
import { CompanySelector } from '../../../components/selectors/CompanySelector/CompanySelector';
import { SignalLevelSelector } from '../../../components/selectors/SignalLevelSelector/SignalLevelSelector';
import { TimeRangeSelector } from '../../../components/selectors/TimeRangeSelector/TimeRangeSelector';
import { useAuth } from '../../../domain/auth/useAuth';
import { ICleanResponseTrigger } from '../../../domain/clean-response/interface';
import { useSaveCleanResponseTriggerMutation } from '../../../domain/clean-response/queries';
import { minutesToHours } from '../../../domain/common/timeHelpers';
import { HOUR_MINUTES } from '../../../domain/commonConst';
import { UserType } from '../../../domain/user/interface';
import { CalendarEventPreview } from '../../events/components/CalendarEventPreview';
import { COMPANY_TYPE } from 'src/domain/company/interface';

function formatHoursFromNumber(hours?: string | number | null): string | null {
    if (!hours && hours !== 0) return null;

    return hours.toString().padStart(2, '0') + ':00';
}

//library at backEnd return 23:59 instead 00:00
function normalizeTime(time?: string | null) {
    if (time === '23:59') return 24;

    return time ? parseFloat(time) : time;
}

function transformTriggerToInitialValues(trigger: Partial<ICleanResponseTrigger>) {
    return {
        ...trigger,
        market: trigger.market || null,
        carbon_intensity: trigger.carbon_intensity,
        duration: minutesToHours(trigger.duration!),
        start_time: normalizeTime(trigger.start_time),
        end_time: normalizeTime(trigger.end_time),
    };
}

interface ICleanResponseTriggerModalProps {
    onClose: Function;
    trigger: ICleanResponseTrigger | Partial<ICleanResponseTrigger>;
    isEditMode: boolean;
}

export const CleanResponseTriggerModal: React.FC<ICleanResponseTriggerModalProps> = ({ trigger, onClose, isEditMode }) => {
    const { notification } = App.useApp();
    const [form] = Form.useForm();

    const startTime = Form.useWatch('start_time', form);
    const endTime = Form.useWatch('end_time', form);
    const carbonIntensity = Form.useWatch('carbon_intensity', form);
    const duration = Form.useWatch('duration', form);
    const market = Form.useWatch('market', form);

    const [cleanResponseMarketErrors, setCleanResponseMarketErrors] = useState<string[]>([]);

    const { mutateAsync: saveCleanResponseTrigger, isLoading } = useSaveCleanResponseTriggerMutation();

    const auth = useAuth()!;
    const isAdminRoleType = auth?.user?.isAdminRoleType();
    const isCustomerRoleType = useMemo(() => auth.user?.user_type === UserType.CUSTOMER, [auth]);

    const onFormFinish = async (trigger: ICleanResponseTrigger) => {
        const preparedTrigger: ICleanResponseTrigger = {
            ...trigger,
            company_id: isAdminRoleType ? trigger.company_id : auth!.user!.company_id,
            duration: trigger.duration * HOUR_MINUTES,
            start_time: formatHoursFromNumber(trigger.start_time),
            end_time: formatHoursFromNumber(trigger.end_time),
        };

        try {
            await saveCleanResponseTrigger(preparedTrigger);

            notification.success({ key: 'clean-response-trigger-info', message: 'Clean Response Trigger Saved!' });
            onClose(preparedTrigger);
        } catch (error: any) {
            notification.error({ key: 'clean-response-trigger-error', message: error?.message || 'Cannot Save Clean Response Trigger' });
        }
    };

    const handleCancel = () => {
        onClose();
    };

    const handleTimeRangeChange = (fieldName: string, value: number | null) => {
        form.setFieldsValue({ [fieldName]: value });
    };

    const handleFieldChange = (fieldName: string) => (value: number) => {
        form.setFieldsValue({ [fieldName]: value });
    };

    const handleMarketChange = (value: string | null) => {
        form.setFieldsValue({ market: value?.toLowerCase() });
    };

    const cleanResponseMarketValidator = (rule: any, value: string) => {
        if (!value) {
            setCleanResponseMarketErrors([rule.message]);
            return Promise.reject();
        }

        setCleanResponseMarketErrors([]);
        return Promise.resolve();
    };

    return (
        <Modal
            open
            destroyOnClose
            title={isEditMode ? 'Edit Clean Response Trigger' : 'New Clean Response Trigger'}
            width={600}
            onCancel={handleCancel}
            footer={[
                <Button key='clean-response-trigger-modal-cancel' onClick={handleCancel}>
                    Cancel
                </Button>,
                <Button key='clean-response-trigger-modal-save' type='primary' loading={isLoading} onClick={form.submit}>
                    Save
                </Button>,
            ]}
            data-cy='create-edit-modal'
        >
            <Form
                form={form}
                name='clean-response-trigger-form'
                preserve={false}
                layout='vertical'
                onFinish={onFormFinish}
                initialValues={transformTriggerToInitialValues(trigger)}
            >
                <Form.Item name='id' hidden>
                    <Input />
                </Form.Item>

                {isAdminRoleType && (
                    <Form.Item
                        hasFeedback
                        name='company_id'
                        label={<Typography.Text strong>Company</Typography.Text>}
                        rules={[{ required: true, message: 'Please select company!' }]}
                    >
                        <CompanySelector onlyTypes={[COMPANY_TYPE.CUSTOMER_COMPANY]} />
                    </Form.Item>
                )}

                {isCustomerRoleType
                    ? (
                        <Form.Item
                            required
                            hasFeedback
                            name='market'
                            label={<Typography.Text strong>Select target sites</Typography.Text>}
                            rules={[{
                                required: true,
                                message: 'Please select target sites!',
                                validator: cleanResponseMarketValidator
                            }]}
                            htmlFor='cleanResponseGroupedSitesSelector'
                        >
                            <CleanResponseTargetSitesSelector market={market} onChange={handleMarketChange}
                                companyId={auth.user!.company_id}
                                hasError={cleanResponseMarketErrors.length > 0} />
                        </Form.Item>
                    )
                    : (
                        <Form.Item
                            required
                            hasFeedback
                            name='market'
                            label={<Typography.Text strong>Market</Typography.Text>}
                            rules={[{ required: true, message: 'Please select market!' }]}
                        >
                            <CleanResponseMarketSelector />
                        </Form.Item>
                    )}

                <CarbonIntensityInput value={carbonIntensity} onChange={handleFieldChange('carbon_intensity')} />

                <Row>
                    <Col xs={24}>
                        <Form.Item
                            label={<Typography.Text strong>Trigger Time</Typography.Text>}
                            tooltip='Start and end hours of the day to monitor this specific price trigger, and only create an event during this time'
                            htmlFor='startTime'
                        >
                            <TimeRangeSelector
                                startTime={startTime}
                                endTime={endTime}
                                onChange={handleTimeRangeChange}
                                startTimeFieldName='start_time'
                                endTimeFieldName='end_time'
                            />
                        </Form.Item>
                    </Col>
                </Row>
                <Card
                    title='Event'
                    extra={<Tooltip
                        title='When this trigger is activated we will create an event with this configuration'><QuestionCircleOutlined
                            style={{ color: 'rgba(0, 0, 0, 0.45)' }} /></Tooltip>}
                    bordered={false}
                    style={{ backgroundColor: 'rgba(49, 172, 171, 0.1)' }}
                >
                    <Row gutter={[16, 16]}>
                        <Col xs={8}>
                            <EventDurationInput name='duration' value={duration}
                                onChange={handleFieldChange('duration')} />
                        </Col>
                        <Col xs={8}>
                            <Form.Item label={<Typography.Text strong>Signal Level</Typography.Text>} name='signal' required style={{ width: '100%' }}>
                                <SignalLevelSelector />
                            </Form.Item>
                        </Col>

                    </Row>
                    <Row gutter={[16, 16]}>
                        <Col xs={24}>
                            <Form.Item
                                name='cancel_when_intensity_lower'
                                label={<Typography.Text strong>Stop event if Carbon Intensity drops below
                                    trigger</Typography.Text>}
                                valuePropName='checked'
                            >
                                <Switch />
                            </Form.Item>
                        </Col>
                    </Row>
                </Card>

                <div style={{ paddingTop: 16 }}>
                    <CalendarEventPreview
                        isCleanResponse={true}
                        preEventDurationHours={0}
                        eventDurationHours={duration}
                        postEventDurationHours={0}
                        secondaryPreEventDurationHours={0}
                    />
                </div>
            </Form>
        </Modal>
    );
};
