import App from 'antd/lib/app';
import Collapse from 'antd/lib/collapse';
import Empty from 'antd/lib/empty';
import Flex from 'antd/lib/flex';
import Spin from 'antd/lib/spin';
import Tooltip from 'antd/lib/tooltip';
import Typography from 'antd/lib/typography';
import { orderBy } from 'lodash';
import React, { useMemo } from 'react';
import { ISanFormItem } from '../../domain/site/interface';
import { useSiteSanInfoSourceListQuery } from '../../domain/site/queries';
import { ActiveSanTag } from './ActiveSanTag';
import { SanDetail } from './SanDetail';
import { SuggestedFormatIcon } from './SuggestedSanFormatIcon';

interface ISanDetailsListProps {
    siteId: number | null;
    utility: string | null;
    market: string | null;
    sans: ISanFormItem[];
}

export const SanDetailsList: React.FC<ISanDetailsListProps> = ({ siteId, utility, market, sans: initialSans }) => {
    const { notification } = App.useApp();
    const {
        isFetching,
        data: sanInfoSources = [],
        isError,
    } = useSiteSanInfoSourceListQuery(
        siteId,
        initialSans.map(san => san.service_account_number)
    );

    const sans = useMemo(
        () => orderBy(initialSans, ['is_active', 'service_account_number'], ['desc', 'asc']),
        [initialSans]
    );

    if (isError) {
        notification.error({ key: 'san-details-list-error', message: 'Cannot load san details!' });
    }

    if (isFetching) {
        return (
            <Flex style={{ height: 90 }} justify="center" align="center">
                <Spin />
            </Flex>
        );
    }

    if (!sans || sans.length === 0) {
        return (
            <Flex style={{ height: 90 }} justify="center" align="center">
                <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="Please, add your SAN to see details" />
            </Flex>
        );
    }

    return (
        <Collapse>
            {sans.map((san, index) => {
                const sanInfo = sanInfoSources.find(sanInfo => sanInfo.san === san.service_account_number);

                return (
                    <Collapse.Panel
                        key={index}
                        header={
                            <Flex justify="space-between" align="center">
                                <Flex align="center">
                                    <Tooltip title={san.service_account_number} placement="topRight">
                                        <Typography.Text ellipsis style={{ maxWidth: '330px' }}>
                                            Enrollment ID - {san.service_account_number}
                                        </Typography.Text>
                                    </Tooltip>

                                    <SuggestedFormatIcon
                                        utility={utility}
                                        market={market}
                                        san={san.service_account_number}
                                    />
                                </Flex>

                                <ActiveSanTag isActive={san.is_active} />
                            </Flex>
                        }
                    >
                        {sanInfo?.sources ? (
                            <SanDetail sanInfo={sanInfo} />
                        ) : (
                            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                        )}
                    </Collapse.Panel>
                );
            })}
        </Collapse>
    );
};
