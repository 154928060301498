import { useMemo } from 'react';
import maxBy from 'lodash/maxBy';

import ReactEChartsCore from 'echarts-for-react/lib/core';
import * as echarts from 'echarts/core';
import { HeatmapChart } from 'echarts/charts';
import { TooltipComponent, GridComponent, VisualMapComponent, DataZoomComponent } from 'echarts/components';
import { CanvasRenderer } from 'echarts/renderers';

import differenceInCalendarDays from 'date-fns/differenceInCalendarDays';
import { IEnergyData } from '../../../../domain/energy/interface';
import { IDateRange } from '../../../interface';
import { GET_ROW_HEIGHT_TYPE, calculateChartHeight } from '../../../../domain/common/chart';
import { HOURS } from '../../../../domain/commonConst';
import { formatEnergyUsageData, formatTimeByHour, prepareEnergyDataForHeatMap } from './prepareEnergyDataForHeatMap';
import { format, startOfDay } from 'date-fns';
import { IEventData } from '../../../../domain/event/getEventsDataService';
import isNil from 'lodash/isNil';
import { SECONDARY_COLOR } from 'src/theme';

echarts.use([TooltipComponent, GridComponent, CanvasRenderer, HeatmapChart, VisualMapComponent, DataZoomComponent]);

interface IEnergyHeatMapChart {
    data: IEnergyData[];
    dateRange: IDateRange;
    energyBaselineData: [number, number][];
    eventData: IEventData[];
    energyPerformanceData: [number, number][];
}

export const DEFAULT_HEAT_MAP_MAX_VALUE = 5000;

const formatEnergyUsageTimeMap = (dateRange: IDateRange, baselineData: IEnergyHeatMapChart['energyBaselineData']) => {
    const baselineMap = new Map<string, number | null>();
    const formattedEnergyUsageData = formatEnergyUsageData(dateRange, baselineData);
    formattedEnergyUsageData.forEach(([timestamp, usage]) => {
        const hour = formatTimeByHour(timestamp);
        const date = format(startOfDay(timestamp), 'EEE, dd-LLL-yyyy');
        const key = `${hour}::${date}`;
        baselineMap.set(key, usage);
    });
    return baselineMap;
};

const getChartHeight = (dateRange: IDateRange) => {
    const numberOfRows = differenceInCalendarDays(dateRange.end, dateRange.start);
    const height = calculateChartHeight(numberOfRows, GET_ROW_HEIGHT_TYPE.INTERVAL_DATA);
    return height;
};

export const EnergyHeatMapChart = ({
    data,
    dateRange,
    energyBaselineData,
    eventData,
    energyPerformanceData,
}: IEnergyHeatMapChart) => {
    const preparedEnergyData = useMemo(
        () => prepareEnergyDataForHeatMap(data, dateRange, eventData),
        [data, dateRange, eventData]
    );
    const energyBaselineMap = useMemo(
        () => formatEnergyUsageTimeMap(dateRange, energyBaselineData),
        [energyBaselineData, dateRange]
    );
    const energyPerformanceMap = useMemo(
        () => formatEnergyUsageTimeMap(dateRange, energyPerformanceData),
        [energyPerformanceData, dateRange]
    );

    const {
        value: [, , maxUsage],
    } = maxBy(preparedEnergyData, ({ value: [, , usage] }) => usage) || { value: [] };
    const height = getChartHeight(dateRange);

    const option = {
        tooltip: {
            formatter: (params: any) => {
                const [time, date, usage] = params.value || [];

                const key = `${time}::${date}`;
                const baselineValue = energyBaselineMap.get(key) ? `${energyBaselineMap.get(key)} kW` : 'No data';
                const performance = energyPerformanceMap.get(key);

                const performanceText = params.data?.containsEventMinutes
                    ? !isNil(performance)
                        ? `</br>${params.marker} performance: <strong>${Math.max(performance, 0)} kW</strong>`
                        : `</br>${params.marker} performance: <strong>No data</strong>`
                    : '';

                return `${params.marker}<strong>${usage} kW</strong></br>${params.marker} baseline: <strong>${baselineValue}</strong>${performanceText}</br> ${date}, ${time}`;
            }
        },
        grid: {
            top: 100,
            bottom: 80,
            left: '10%',
            right: '10%',
        },
        xAxis: {
            type: 'category',
            data: HOURS,
            splitArea: {
                show: true,
            },
        },
        yAxis: {
            type: 'category',
            splitArea: {
                show: true,
            },
        },
        visualMap: {
            min: 0,
            max: maxUsage || DEFAULT_HEAT_MAP_MAX_VALUE,
            calculable: true,
            orient: 'horizontal',
            left: 'center',
            top: 30,
        },
        itemHeight: 300,
        series: [
            {
                type: 'heatmap',
                data: preparedEnergyData,
                label: {
                    show: true,
                },
                emphasis: {
                    itemStyle: {
                        shadowBlur: 5,
                        shadowColor: 'rgba(0, 0, 0, 0.5)',
                    },
                },
            },
        ],
    };

    return (
        <div className="energy-chart-container">
            <div style={{ textAlign: 'center', color: SECONDARY_COLOR, marginTop: 10, paddingBottom: 0 }}>
                Heat map displays total energy usage for all selected sites. Hover over a cell for more information.
            </div>
            <ReactEChartsCore
                echarts={echarts}
                option={option}
                notMerge
                lazyUpdate
                style={{ height: `${height}px`, minHeight: '600px' }}
            />
        </div>
    );
};
