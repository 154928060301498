import { useMutation, useQuery } from '@tanstack/react-query';
import { QueryKey } from '../../query-client';
import { IPagination } from '../IPagination';
import {
    deleteCloudVen,
    deleteCustomerVen,
    fetchCloudVens,
    fetchCustomerVens,
    fetchVenOverview,
    saveCloudVen,
    saveCustomerVen,
    getCloudVenCertificateDetails,
    fetchCustomerVensControlProviders,
} from './index';
import {
    ICloudVen,
    ICustomerVen,
    IFetchVenPageQuery,
    IVenOverview,
    ICloudVenCertificateDetails,
    ICustomerVenControlProviders,
} from './interface';
import { IFetchPageQuery } from '../IFetchQueryOptions';

export const useVenOverviewQuery = (query: { companyId?: number }, options = {}) => {
    return useQuery<IVenOverview, Error>({
        queryKey: [QueryKey.VEN_OVERVIEW, query],
        queryFn: () => fetchVenOverview(query?.companyId),
        ...options,
    });
};

export const useCustomerVenListQuery = (query: IFetchVenPageQuery, options = {}) => {
    return useQuery<IPagination<ICustomerVen>, Error>({
        queryKey: [QueryKey.CUSTOMER_VENS, query],
        queryFn: () => fetchCustomerVens(query),
        ...options,
    });
};

export const useCustomerVenControlProvidersListQuery = (query: IFetchPageQuery, options = {}) => {
    return useQuery<ICustomerVenControlProviders[], Error>({
        queryKey: [QueryKey.CUSTOMER_VENS_CONTROL_PROVIDERS, query],
        queryFn: () => fetchCustomerVensControlProviders(query),
        ...options,
    });
};

export const useSaveCustomerVenMutation = () => {
    return useMutation<ICustomerVen, Error, ICustomerVen>({
        mutationFn: (customerVen: ICustomerVen) => saveCustomerVen(customerVen),
    });
};

export const useDeleteCustomerVenMutation = () => {
    return useMutation<void, Error, ICustomerVen>({
        mutationFn: (customerVen: ICustomerVen) => deleteCustomerVen(customerVen),
    });
};

export const useCloudVenListQuery = (query: IFetchVenPageQuery, options = {}) => {
    return useQuery<IPagination<ICloudVen>, Error>({
        queryKey: [QueryKey.CLOUD_VENS, query],
        queryFn: () => fetchCloudVens(query),
        ...options,
    });
};

export const useCloudVenCertificateDetailsQuery = (certificateText: string) => {
    return useQuery<ICloudVenCertificateDetails, Error>({
        retry: false,
        queryKey: [QueryKey.CLOUD_VEN_CERTIFICATE],
        queryFn: () => getCloudVenCertificateDetails(certificateText),
    });
};

export const useSaveCloudVenMutation = () => {
    return useMutation<ICloudVen, Error, ICloudVen>({
        mutationFn: (cloudVen: ICloudVen) => saveCloudVen(cloudVen),
    });
};

export const useDeleteCloudVenMutation = () => {
    return useMutation<void, Error, ICloudVen>({
        mutationFn: (cloudVen: ICloudVen) => deleteCloudVen(cloudVen),
    });
};
