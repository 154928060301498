import { ICompany } from '../../../domain/company/interface';
import { IProgram } from '../../../domain/program/interface';
import { ICreateSite } from '../../../domain/site/interface';

export function importedRecordToCreateSite(record: any[], companies: ICompany[], providers: ICompany[], utilityCustomers: ICompany[], programs: IProgram[]): ICreateSite {
    const [
        companyName,
        controlProviderName,
        utilityCustomerName,
        programNamesStr,
        siteName,
        siteStoreNumber,
        address,
        city,
        state,
        zip,
        market,
        loadZone,
        estimatedKw,
        serviceAccountNumber, // string or comma separated string
        utility,
        slap,
        network,
        eventOffset,
        eventMaxDuration,
        createVen,
        partner,
    ] = record;

    const company = companies.find(c => c.company_name.toLowerCase() === companyName.toLowerCase());
    const controlProvider = controlProviderName ? providers.find(c => c.company_name.toLowerCase() === controlProviderName.toLowerCase()) : null;
    const utilityCustomer = utilityCustomerName ? utilityCustomers.find(c => c.company_name.toLowerCase() === utilityCustomerName.toLowerCase()) : null;

    const programNames = programNamesStr ? programNamesStr.split(',').map((p: string) => p.trim()) : [];

    const programIds: number[] = [];
    programNames.forEach((name: string) => {
        const program = programs.find(p => p.name.toLowerCase() === name.toLowerCase());
        if (program) {
            programIds.push(program.program_id);
        }
    });

    return {
        company_id: company?.company_id!,
        control_provider_id: controlProvider?.company_id || null,
        utility_customer_id: utilityCustomer?.company_id || null,
        program_ids: programIds,
        site_name: siteName,
        site_store_number: siteStoreNumber ? String(siteStoreNumber) : undefined,
        create_customer_ven: Boolean(createVen),
        site_address: address,
        site_city: city,
        site_state: state,
        site_zip: zip ? String(zip) : '',
        site_country: null,
        site_lat: null,
        site_long: null,
        site_timezone: null,
        google_place_id: undefined,
        site_estimated_kw: estimatedKw ? Number(estimatedKw) : undefined,
        site_slap: slap ? String(slap) : undefined,
        site_network: network ? String(network) : undefined,
        site_utility: utility ? String(utility) : undefined,
        event_offset: eventOffset ? parseInt(eventOffset) : undefined,
        event_max_duration: eventMaxDuration ? parseInt(eventMaxDuration) : undefined,
        partner_id: partner ? String(partner) : undefined,
        site_load_zone: loadZone ? String(loadZone) : undefined,
        lmp_market: market ? String(market) : undefined,
        sans: serviceAccountNumber ? serviceAccountNumber.split(',').map((san: string) => ({ service_account_number: san.trim(), is_active: true })) : [],
    };
}
