import orderBy from 'lodash/orderBy';
import React, { useMemo } from 'react';
import ExclamationCircleOutlined from '@ant-design/icons/ExclamationCircleOutlined';
import App from 'antd/lib/app';
import Button from 'antd/lib/button';
import Collapse from 'antd/lib/collapse';
import Empty from 'antd/lib/empty';
import Flex from 'antd/lib/flex';
import Typography from 'antd/lib/typography';
import isEmpty from 'lodash/isEmpty';
import { IMeter } from '../../domain/site/interface';
import Row from 'antd/lib/row';
import { defaultDateFormat } from '../../domain/common/dateFormatters';
import Col from 'antd/lib/col';
import { SiteFullAddress } from './SiteAddress';

interface IMeterListProps {
    meters: IMeter[];
    onUnlinkMeter: (salesforceId: string) => void;
}

export const MeterList: React.FC<IMeterListProps> = ({ meters, onUnlinkMeter }) => {
    const { modal } = App.useApp();
    const sortedMeters = useMemo(() => orderBy(meters, ['name'], ['asc']), [meters]);

    const handleUnlinkMeter = (meter: IMeter) => (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
        event.stopPropagation();

        modal.confirm({
            icon: <ExclamationCircleOutlined />,
            content: `You are going to unlink meter "${meter.name}". Are you sure?`,
            onOk() {
                onUnlinkMeter(meter.salesforce_id);
            },
            onCancel() {
                console.log('Cancel');
            },
        });
    };

    if (isEmpty(sortedMeters)) {
        return (
            <Flex style={{ height: 90 }} justify="center" align="center">
                <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="Link Salesforce Meters to see them in list" />
            </Flex>
        );
    }

    return (
        <Collapse>
            {sortedMeters.map((meter: IMeter, index) => (
                <Collapse.Panel
                    key={index}
                    header={
                        <Flex justify="space-between" align="center">
                            <Typography.Text
                                ellipsis={{ tooltip: { title: meter.name } }}
                                style={{ maxWidth: '330px' }}
                            >
                                {meter.name}
                            </Typography.Text>
                            <Button size="small" onClick={handleUnlinkMeter(meter)}>
                                Unlink
                            </Button>
                        </Flex>
                    }
                >
                    <Row gutter={[16, 8]}>
                        <ListItem label="Salesforce ID" value={meter.salesforce_id} />
                        <ListItem label="Name" value={meter.name} />
                        <ListItem label="Enrollment ID" value={meter.service_account_number || '-'} />
                        <ListItem label="Store Number" value={meter.store_number || '-'} />
                        <ListItem label="Account Name" value={meter?.account?.name || '-'} />
                        <ListItem label="Site Max Duration" value={meter.event_max_duration || '-'} />
                        <ListItem label="Site Enrolled (kW)" value={meter.contract_max_per_site || '-'} />
                        <ListItem label="Zone SubLAP" value={meter.zone_sub_lap || '-'} />
                        <ListItem
                            label="Address"
                            value={
                                <SiteFullAddress
                                    address={meter.address}
                                    city={meter.city}
                                    state={meter.state}
                                    zip={meter.zip}
                                    country={meter.country}
                                />
                            }
                        />
                        <ListItem
                            label="Salesforce created by"
                            value={
                                <>
                                    {meter.salesforce_created_by || '-'} /{' '}
                                    {defaultDateFormat(meter.salesforce_created_at, '-')}
                                </>
                            }
                        />
                        <ListItem
                            label="Salesforce updated by"
                            value={
                                <>
                                    {meter.salesforce_updated_by || '-'} /{' '}
                                    {defaultDateFormat(meter.salesforce_updated_at, '-')}
                                </>
                            }
                        />
                    </Row>
                </Collapse.Panel>
            ))}
        </Collapse>
    );
};

interface IListItemProps {
    label: string;
    value?: string | number | React.JSX.Element;
    labelSpan?: number;
    valueSpan?: number;
}

function ListItem({ label, value, labelSpan = 10, valueSpan = 14 }: IListItemProps) {
    return (
        <>
            <Col span={labelSpan}>
                <Typography.Text strong>{label}</Typography.Text>
            </Col>
            <Col span={valueSpan}>
                <Typography.Text>{value || '-'}</Typography.Text>
            </Col>
        </>
    );
}
