import App from 'antd/lib/app';
import InboxOutlined from '@ant-design/icons/InboxOutlined';
import Button from 'antd/lib/button';
import Input from 'antd/lib/input';
import Modal from 'antd/lib/modal';
import Form from 'antd/lib/form';
import Dragger from 'antd/lib/upload/Dragger';
import Typography from 'antd/lib/typography';
import { LIST_IGNORE } from 'antd/lib/upload/Upload';
import { useState } from 'react';
import { ISettlementFile } from '../../domain/settlement/interface';
import { useCreateSettlementMutation } from '../../domain/settlement/queries';
import { Uploader } from '../../domain/settlement/Uploader';
import { CompanySelector } from '../../components/selectors/CompanySelector/CompanySelector';
import Upload from 'antd/lib/upload';
import { COMPANY_TYPE } from 'src/domain/company/interface';

export interface ISettlementFormPros {
    settlement: ISettlementFile | Partial<ISettlementFile>;
    onClose: (settlement?: ISettlementFile | null) => void;
}

const ALLOWED_FILE_TYPES = ['.pdf', '.xls', '.xlsx'];

export const SettlementFileUploadModal = ({ settlement, onClose }: ISettlementFormPros) => {
    const { notification } = App.useApp();
    const [settlementState, setSettlementState] = useState<any>(settlement);
    const [loading, setLoading] = useState<boolean>(false);
    const [file, setFile] = useState<File | null>(null);
    const [form] = Form.useForm();

    const createSettlementMutation = useCreateSettlementMutation();

    async function onFormFinish(settlement: ISettlementFile) {
        if (!file) {
            console.warn(`File is absent!`, file);
            return;
        }

        try {
            console.info('onFormFinish', settlement);

            setLoading(true);
            const uploader = new Uploader();

            const { hash } = await uploader.upload(settlement.company_id, file);

            settlement.settlement_file_hash = hash;
            settlement.settlement_file_name = file?.name;
            settlement.settlement_file_size = file?.size;

            await createSettlementMutation.mutateAsync(settlement);

            setSettlementState(null);
            setLoading(false);
            notification.info({
                key: 'settlement-save-info',
                message: 'Settlement saved',
            });

            onClose(settlement);
        } catch (err: any) {
            setLoading(false);
            notification.error({
                key: 'settlement-save-error',
                message: err?.message || 'Settlement save error!',
            });
        }
    }

    function closeSettlementDialog() {
        setSettlementState(null);
        onClose();
    }

    function fileToVirtualFile(file: ISettlementFile | Partial<ISettlementFile>) {
        if (file?.settlement_file_name) {
            return [{ uid: file.settlement_file_hash || '', name: file.settlement_file_name }];
        }
        return [];
    }

    const uploadProps = {
        accept: ALLOWED_FILE_TYPES.join(','),
        multiple: false,
        onRemove: (file: any) => {
            console.info('uploadProps onRemove', file);
            setFile(null);
        },
        beforeUpload: (file: any) => {
            console.info('uploadProps beforeUpload', file);

            if (file?.size === 0) {
                notification.error({
                    key: 'settlement-upload-error',
                    message: 'The file is empty, its size is zero. Please select another non-empty file for upload.',
                });
                return LIST_IGNORE;
            }

            if (!ALLOWED_FILE_TYPES.some(type => file.name.endsWith(type))) {
                notification.warning({
                    key: 'upload-file-type-notification',
                    message: `Please select "${ALLOWED_FILE_TYPES.join('" or "')}" file.`,
                });
                return Upload.LIST_IGNORE;
            }

            return false;
        },
        onChange: (info: any) => {
            console.log('uploadProps onChange', info);
            if (info.file.status) {
                return;
            }

            /** delete the prev selected file - if a user selected several files */
            if (info.fileList.length > 1) {
                info.fileList.shift();
            }

            setFile(info.file);
        },
        defaultFileList: fileToVirtualFile(settlement),
    };

    return (
        <Modal
            open
            destroyOnClose
            title="Upload settlement file"
            onCancel={closeSettlementDialog}
            footer={[
                <Button key="site-modal-cancel" onClick={closeSettlementDialog}>
                    Cancel
                </Button>,
                <Button key="site-modal-submit" type="primary" disabled={!file} loading={loading} onClick={form.submit}>
                    Save
                </Button>,
            ]}
        >
            <Form
                form={form}
                name="settlement-form"
                preserve={false}
                layout="vertical"
                onFinish={onFormFinish}
                initialValues={settlementState}
            >
                <Form.Item name="settlement_file_id" hidden>
                    <Input />
                </Form.Item>
                <Form.Item
                    name="company_id"
                    label={<Typography.Text strong>Company</Typography.Text>}
                    hasFeedback
                    rules={[{ required: true, message: 'Please select company!' }]}
                >
                    <CompanySelector onlyTypes={[COMPANY_TYPE.CUSTOMER_COMPANY]} />
                </Form.Item>
                <Dragger {...uploadProps}>
                    <p className="ant-upload-drag-icon">
                        <InboxOutlined />
                    </p>
                    <p className="ant-upload-text">Click or drag file to this area to upload</p>
                    <p className="ant-upload-hint">Allowed file extensions: .pdf, .xls, .xlsx</p>
                </Dragger>
            </Form>
        </Modal>
    );
};
