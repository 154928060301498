import { useQueries, useQuery } from '@tanstack/react-query';
import { endOfYear, startOfYear } from 'date-fns';
import sub from 'date-fns/sub';
import { useMemo } from 'react';
import { fetchEventsCountBySites } from '../../../domain/event';
import { fetchSite } from '../../../domain/site';
import { ISite } from '../../../domain/site/interface';
import { QueryKey } from '../../../query-client';

// a range of 4 full years
const startDate = startOfYear(sub(new Date(), { years: 3 }));
const endDate = endOfYear(new Date());

export function useGetMergeSitesData(siteIds: number[]) {
    const siteQueries = useQueries({
        queries: siteIds.map(siteId => ({
            queryKey: [QueryKey.SITES, siteId],
            queryFn: () => fetchSite(siteId, 'company,control_provider,utility_customer,program,san,cloud_ven,customer_ven')
        })),
    });

    const eventsCountQuery = useQuery({
        queryKey: [QueryKey.EVENTS_COUNT, siteIds],
        queryFn: () => fetchEventsCountBySites(
            siteIds,
            startDate,
            endDate
        ),
    });

    const isLoading = useMemo(() =>
        siteQueries.some(query => query.isLoading) || eventsCountQuery.isLoading,
        [siteQueries, eventsCountQuery.isLoading]
    );

    const eventsCountBySites = useMemo(() =>
        eventsCountQuery.data?.data || {},
        [eventsCountQuery.data]
    );

    const sites = useMemo(() =>
        !isLoading ? siteQueries.map(query => query.data).flat() as ISite[] : [],
        [isLoading, siteQueries]
    );

    return {
        sites,
        eventsCountBySites,
        isLoading
    };
}
