import { useState } from 'react';
import Tabs, { TabsProps } from 'antd/lib/tabs';
import Tag from 'antd/lib/tag';
import Col from 'antd/lib/col';
import Row from 'antd/lib/row';
import { ProgramSelector } from '../../../../components/selectors/ProgramSelector/ProgramSelector';
import { CompanySelector } from '../../../../components/selectors/CompanySelector/CompanySelector';
import { SiteSelector } from '../../../../components/selectors/SiteSelector/SiteSelector';
import Typography from 'antd/lib/typography';
import { COMPANY_TYPE } from 'src/domain/company/interface';

export interface IDispatchBy {
    program_ids: number[];
    company_id?: number;
    site_id?: number;
}

export interface ICloudVenDispatchByProps {
    value: {
        program_ids: number[];
        company_id?: number;
        site_id?: number;
    };
    onChange: (val: { program_ids: number[]; site_id?: number }) => void;
}

export const CloudVenDispatchBy = ({ value, onChange }: ICloudVenDispatchByProps) => {
    const { program_ids, company_id, site_id } = value;

    const activeTab = site_id ? 'dispatch-by-site' : 'dispatch-by-program';

    const [dispatchBy, setDispatchBy] = useState<IDispatchBy>({ program_ids, site_id, company_id });

    const handleProgramsChange = (val: any) => {
        const state = { program_ids: val as number[], company_id: undefined, site_id: undefined };

        setDispatchBy(state);
        onChange(state);
    };

    const handleCompanyChange = (val: number) => {
        const state = { program_ids: [], company_id: val, site_id: undefined };

        setDispatchBy(state);
        onChange(state);
    };

    const handleSiteChange = (val: number) => {
        const state = { program_ids: [], company_id: dispatchBy.company_id, site_id: val };

        setDispatchBy(state);
        onChange(state);
    };

    const items: TabsProps['items'] = [
        {
            key: 'dispatch-by-program',
            label: 'Program',
            children: <ProgramSelector
                selectedProgram={dispatchBy.program_ids}
                mode="multiple"
                handleProgram={handleProgramsChange}
                style={{ width: '100%' }}
                allowClear={false}
                tagRender={(props: any) => (
                    <Tag color="gold" closable onClose={props.onClose}>
                        {props.label}
                    </Tag>
                )}
            />
        },
        {
            key: 'dispatch-by-site',
            label: 'Site',
            children: <Row gutter={8}>
                <Col span={12}>
                    <CompanySelector value={company_id} onChange={handleCompanyChange} onlyTypes={[COMPANY_TYPE.CUSTOMER_COMPANY]} />
                </Col>
                <Col span={12}>
                    <SiteSelector companyId={company_id} value={site_id} onChange={handleSiteChange} />

                    {!company_id && (
                        <Typography.Text type="secondary">Select company first to see sites</Typography.Text>
                    )}
                </Col>
            </Row>
        }
    ];

    return (
        <div className="cloud-ven-dispatch-by">
            <Tabs type="card" defaultActiveKey={activeTab} items={items} />
        </div >
    );
};
