import { useEffect, useState } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { useCompanies } from '../../components/selectors/GlobalCompanySelector/CompanyContext';
import { useAuth } from '../../domain/auth/useAuth';
import { VIEW_MODE } from '../interface';
import { useDocumentTitle } from '../../components/useDocumentTitle';

export function Vens() {
    const { pathname } = useLocation();
    const currentView = pathname.split('/')[2] || VIEW_MODE.OVERVIEW;

    useDocumentTitle('VEN');

    const auth = useAuth()!;
    const { company, companies } = useCompanies()!;
    const navigate = useNavigate();
    const [view, setView] = useState(currentView);

    const companyQuery = company ? `?companyId=${company}` : '';

    useEffect(() => {
        setView(currentView);
    }, [currentView]);

    const handleViewMode = (newView: string) => {
        navigate(`/ven-devices/${newView}${companyQuery}`);
    };

    return <Outlet context={{ view, handleViewMode, auth, company, companies }} />;
}
